import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FirebaseContext } from './../../context';

import { ReactComponent as DonwloadIcon } from './assets/donwload.svg';

const ApplicationHipotecarioFiles = ({ application }) => {
  const firebase = useContext(FirebaseContext);
  const [cedulaFiles, setcedulaFiles] = useState([]);
  const [liquidacionesFiles, setliquidacionesFiles] = useState([]);
  const [certificadoAFPFiles, setcertificadoAFPFiles] = useState([]);
  let { id } = useParams();

  const cedulaFileNames = application.cedula.map((d) => d.split(id + '/')[1]);

  const liquidacionesFileNames = application.liquidaciones.map(
    (d) => d.split(id + '/')[1]
  );

  const certificadoAFPFileNames = application.certificadoAFP.map(
    (d) => d.split(id + '/')[1]
  );

  useEffect(() => {
    const cedula = application.cedula.map(firebase.getDownloadURL);
    const liquidaciones = application.liquidaciones.map(firebase.getDownloadURL);
    const certificadoAFP = application.certificadoAFP.map(firebase.getDownloadURL);

    Promise.all(cedula.map((p) => p.catch((e) => null))).then(setcedulaFiles);
    Promise.all(liquidaciones.map((p) => p.catch((e) => null))).then(
      setliquidacionesFiles
    );
    Promise.all(certificadoAFP.map((p) => p.catch((e) => null))).then(
      setcertificadoAFPFiles
    );
  }, [application, firebase.getDownloadURL]);

  return (
    <div className="files">
      <h4>Descargar archivos adjuntos</h4>
      <div>
        {cedulaFiles
          .filter((d) => d)
          .map((d, i) => {
            return (
              <a href={d} target="_blank" rel="noopener noreferrer" download key={i}>
                <i className="fas fa-download" />
                {cedulaFileNames[i]}
                <DonwloadIcon />
              </a>
            );
          })}
      </div>
      <div>
        {liquidacionesFiles
          .filter((d) => d)
          .map((d, i) => {
            return (
              <a href={d} target="_blank" rel="noopener noreferrer" download key={i}>
                <i className="fas fa-download" />
                {liquidacionesFileNames[i]}
                <DonwloadIcon />
              </a>
            );
          })}
      </div>
      <div>
        {certificadoAFPFiles
          .filter((d) => d)
          .map((d, i) => {
            return (
              <a href={d} target="_blank" rel="noopener noreferrer" download key={i}>
                <i className="fas fa-download" />
                {certificadoAFPFileNames[i]}
                <DonwloadIcon />
              </a>
            );
          })}
      </div>
    </div>
  );
};
const ApplicationConsumoFiles = () => {
  // Credito de consumo no tiene asociados archivos que envia el usuario
  return <p></p>;
};

const ApplicationFilesIndex = ({ application, applicationType }) => {
  switch (applicationType) {
    case 'hipotecario':
      return (
        <div id={'files-container'}>
          <ApplicationHipotecarioFiles application={application} />
        </div>
      );
    case 'consumo':
      return (
        <div id={'files-container'}>
          <ApplicationConsumoFiles application={application} />
        </div>
      );

    default:
      return <div id={'files-container'}></div>;
  }
};

export default ApplicationFilesIndex;
