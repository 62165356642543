// Importar librerías
import React from 'react';

// Importar otros componentes
import ApplicationAnswerHipotecario from './ApplicationAnswerHipotecario';
import ApplicationAnswerConsumo from './ApplicationAnswerConsumo';

const ApplicationAnswerIndex = ({
  application,
  applicationType,
  answerLoadingStatus,
  canEdit,
}) => {
  switch (applicationType) {
    case 'portabilidad-hipotecario':
    case 'hipotecario':
      return (
        <ApplicationAnswerHipotecario
          application={application}
          answerLoadingStatus={answerLoadingStatus}
          canEdit={canEdit}
        />
      );
    case 'portabilidad-consumo':
    case 'consumo':
    case 'automotriz':
      return (
        <ApplicationAnswerConsumo
          application={application}
          answerLoadingStatus={answerLoadingStatus}
          canEdit={canEdit}
        />
      );

    default:
      return <div>Error</div>;
  }
};

export default ApplicationAnswerIndex;
