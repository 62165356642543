// *** User API ***

class userLogic {
  constructor(db) {
    this.db = db;
  }

  user = (uid) => this.db.doc(`users/${uid}`);

  users = () => this.db.collection('users');
}

export default userLogic;
