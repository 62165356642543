// Importar librerías
import React, { useState, useEffect } from 'react';
import { Input, message, Tooltip } from 'antd';
import { ReactComponent as SendIcon } from './assets/send.svg';
import moment from 'moment';

// Definir formato de fecha
const DATE_FORMAT = 'DD/MM/YYYY - HH:mm';

// Importar componente de búsqueda
const { Search } = Input;

const TakenApplicationChat = ({ application, authUser, firebase, rejected }) => {
  // Obtener información del chat
  const chat = application.get('chat');

  // Definir state
  const [loading, setLoading] = useState(false);
  const [messageText, setMessageText] = useState('');

  // Definir effect para aplicar efecto al html
  useEffect(() => {
    // TODO funciona a veces
    const htmlDoc = document.getElementById('chat').getElementsByTagName('*');
    if (htmlDoc[htmlDoc.length - 1])
      htmlDoc[htmlDoc.length - 1].scrollIntoView({ behavior: 'smooth' });
  }, []);

  // Definir effect para colocar mensaje inicial en el chat
  useEffect(() => {
    if (chat.length === 0) {
      // Definir mensaje inicial
      const initialText = `Hola ${application.get('user').firstName} ${
        application.get('user').lastName
      }, soy ${authUser.firstName} ${
        authUser.lastName
      } tu ejecutivo. Cualquier duda sobre la oferta que ${
        application.get('answers')[0].bankName
      } te ha entregado, acá estoy para responderla.`;

      // Enviar mensaje
      sendMessage(initialText);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   *
   * @param {*} messageVal
   * Guarda la información de un mensaje en la Bd.
   */
  const sendMessage = async (messageVal) => {
    // Validar que hay un mensaje
    if (!messageVal) return false;

    // Habilitar spinner de carga
    setLoading(true);

    // Intentar almacenar en la BD
    try {
      await firebase.applicationLogic.addChatMessage(
        authUser.uid,
        application.id,
        messageVal
      );

      // Reinicializar valor del mensaje
      setMessageText('');

      // Ubicar texto en el chat
      const htmlDoc = document.getElementById('chat').getElementsByTagName('*');
      if (htmlDoc[htmlDoc.length - 1])
        htmlDoc[htmlDoc.length - 1].scrollIntoView({ behavior: 'smooth' });

      // Enviar notificación al usuario
      const data = {
        email: application.get('user').email,
        body: `
        En el chat tienes un nuevo mensaje de tu ejecutivo <b>${authUser.firstName} ${authUser.lastName}</b>, para verlo ingresa a tu perfil en:
        <br><br>
        https://flit.cl/perfil/cotizaciones/${application.id}
        `,
      };
      await firebase.functions.httpsCallable('sendInfoMailer')(data);
    } catch (error) {
      message.error('Hubo un problema al intentar enviar el mensaje');
    }

    // Deshabilitar spinner de carga
    setLoading(false);
  };

  // Renderizar componente
  return (
    <div id={'chat-container'}>
      <h4>
        {authUser.firstName} {authUser.lastName}
      </h4>
      <div className={'chat'} id={'chat'}>
        {chat.map((d, i) => {
          const isAuthor = d.senderId === authUser.uid;
          return (
            <div key={d.message + i} className={isAuthor ? 'bank' : 'user'}>
              <div className={'message'}>
                <p>{d.message}</p>
                <span>{moment(d.createdAt.toDate()).format(DATE_FORMAT)}</span>
              </div>
            </div>
          );
        })}
      </div>
      <Tooltip title={rejected ? 'Rechazaste esta solicitud' : ''}>
        <div className={'send'}>
          <Search
            disabled={rejected}
            onChange={(e) => setMessageText(e.target.value)}
            value={messageText}
            className={'send-message'}
            placeholder="Escribe tu mensaje aqui"
            enterButton={<SendIcon />}
            size="middle"
            onSearch={sendMessage}
            loading={loading}
          />
        </div>
      </Tooltip>
    </div>
  );
};

export default TakenApplicationChat;
