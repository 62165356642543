// Importar librerías
import React, { useContext } from 'react';
import { Switch, Route } from 'react-router-dom';

// Importar utilidades y constantes
import { withAuthorization } from '../../utils';
import { bankGeneralUserCondition } from '../../constants/conditions';

// Importar context de user
import { AuthUserContext } from '../../context';

// Importar otros componentes
import ExecutiveList from './ExecutiveList';

// Importar rutas
import * as ROUTES from '../../constants/routes';

const ExecutivesPage = () => {
  // Obtener context de user
  const authUser = useContext(AuthUserContext);

  // Renderizar componente
  return (
    <div id="main-container">
      <Switch>
        {/* Carga por defecto la lista */}
        <Route
          exact
          path={ROUTES.EXECUTIVES_PAGE}
          render={(props) => <ExecutiveList {...props} authUser={authUser} />}
        />
      </Switch>
    </div>
  );
};

export default withAuthorization(bankGeneralUserCondition)(ExecutivesPage);
