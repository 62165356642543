// Importar librerías
import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { compose } from 'recompose';
import { Form, Input, Button, Tabs } from 'antd';

// Importar decoradores
import { withFirebase } from './../../utils';

// Importar rutas
import * as ROUTES from './../../constants/routes';

// Importar otros componentes
import { ReactComponent as BigLogo } from './../icons/session/big-logo.svg';

// Definir subcomponentes
const { TabPane } = Tabs;

// Renderizar componente de la página principal
const SignInPage = () => {
  // Renderizar
  return (
    <div className="sign-in-div">
      <div className="mini-phone-background">
        <div className={'flit-logo'}>
          <BigLogo />
        </div>
        <div className={'sign-in-form'}>
          <Tabs defaultActiveKey="1">
            <TabPane tab="Iniciar Sesión" key="1">
              <SignInForm />
            </TabPane>
            <TabPane tab="Crear cuenta" key="2">
              Contáctate con un administrador para poder entrar a este sitio
            </TabPane>
          </Tabs>
        </div>
      </div>
    </div>
  );
};

// Definir estado inicial
const INITIAL_STATE = {
  email: '',
  password: '',
  error: null,
};

class SignInFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  /**
   *
   * @param {*} values
   * Envía la información del usuario para hacer el proceso de autentitación en firebase.
   */
  onSubmit = (values) => {
    const { email, password } = values;

    this.props.firebase.authLogic
      .doSignInWithEmailAndPassword(email, password)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
        this.props.history.push(ROUTES.HOME);
      })
      .catch((error) => {
        this.setState({ error });
      });
  };

  // Renderizar
  render() {
    const { error } = this.state;

    return (
      <Form
        name="normal_login"
        initialValues={{
          remember: true,
        }}
        layout="vertical"
        onFinish={this.onSubmit}
        hideRequiredMark={true}
      >
        <Form.Item
          name="email"
          rules={[
            {
              required: true,
              message: 'Por favor ingresa tu correo',
            },
          ]}
        >
          <Input placeholder="Email" />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: 'Por favor ingresa tu contraseña',
            },
          ]}
        >
          <Input type="password" placeholder="Password" />
        </Form.Item>
        <div className={'form-actions'}>
          <Form.Item className={'link'}>
            <Link to={ROUTES.PASSWORD_FORGET}>¿Olvidaste tu Contraseña?</Link>
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" className={'button'} block>
              Iniciar Sesión
            </Button>
          </Form.Item>
        </div>
        {error && <p>{error.message}</p>}
      </Form>
    );
  }
}

const SignInForm = compose(withRouter, withFirebase)(SignInFormBase);

export default SignInPage;

export { SignInForm };
