// Importar librerías
import React, { useState, useContext } from 'react';
import { Modal, Button, Select, Form, Checkbox, message } from 'antd';

// Importar context de firebase
import { FirebaseContext } from '../../context';

// Importar hooks
import useWindowSize from '../../hooks/useWindowSize';

// Importar subcomponente Option
const { Option } = Select;

const NewFreeExecutiveModal = ({
  openFreeExecutiveModal,
  setOpenFreeExecutiveModal,
  openModalRecord,
  authUser,
  appOptions,
}) => {
  // Obtener información del context de firebase
  const firebase = useContext(FirebaseContext);

  // Definir state
  const [loading, setLoading] = useState(false);

  // Crear instancias de useForm
  const [freeExecutiveFormInstance] = Form.useForm();

  // Obtener tamaño de la ventana
  const windowWidth = useWindowSize();

  /**
   *
   * @param {*} values
   * Almacena la información del nuevo administrador que se está creando en la BD.
   */
  const onFinish = async (values) => {
    // Habilitar carga del spinner
    setLoading(true);

    // Obtener información del ejecutivo seleccionado
    const selectedExecutive = openModalRecord.filter(
      (item) => item.get('email') === values.executiveEmail
    )[0];

    // Agregar información adicional a los valores de entrada
    values = {
      ...values,
      executiveId: selectedExecutive.id,
      bankOfficeId: authUser.bankOfficeId,
      bankOfficeName: authUser.bankOfficeName,
      bankOfficeBoss: authUser.email,
    };

    // Intentar almacenar en la BD
    try {
      await firebase.executiveLogic.updateFreeExecutive(values);

      // Mostrar mensaje de éxito
      message.success('Ejecutivo libre agregado exitosamente');

      // Limpiar campos de formulario
      freeExecutiveFormInstance.resetFields();

      // Terminar ejecución y ocultar modal
      setOpenFreeExecutiveModal(false);
    } catch (error) {
      message.error(error.message);
    }

    // Deshabilitar carga del spinner
    setLoading(false);
  };

  /**
   *
   * @param {*} errorInfo
   * Muestra un error en caso de que no se haya podido guardar la información en la BD.
   */
  const onFinishFailed = (errorInfo) => {
    console.log(errorInfo);
    message.error('Error');
  };

  // Renderizar componente
  return (
    <Modal
      onCancel={() => {
        freeExecutiveFormInstance.resetFields();
        setOpenFreeExecutiveModal(false);
      }}
      width={windowWidth < 992 ? 350 : 520}
      confirmLoading={loading}
      footer={<div id="executive-modal-footer"></div>}
      visible={openFreeExecutiveModal}
    >
      <div id="executive-new-modal-body">
        <h3>Elegir Ejecutivo Libre</h3>
        <Form
          form={freeExecutiveFormInstance}
          id="modal-new-executive-form"
          layout={'vertical'}
          name="link_form"
          hideRequiredMark={true}
          onFinish={(values) => onFinish(values)}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            label="Email del Ejecutivo"
            name="executiveEmail"
            rules={[
              { required: true, message: 'Seleccione el email del ejecutivo libre' },
            ]}
          >
            <Select placeholder="Selecciona">
              {openModalRecord &&
                openModalRecord.map((item) => (
                  <Option key={item.id} value={item.get('email')}>
                    {item.get('email')}
                  </Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Tipo de Solicitudes"
            name="bankOfficeAppTypes"
            rules={[
              {
                required: true,
                message:
                  'Seleccione al menos un tipo de solicitud que maneje el ejecutivo',
              },
            ]}
          >
            <Checkbox.Group options={appOptions} />
          </Form.Item>
          <Form.Item className={'button-holder'}>
            <Button type="primary" htmlType="submit" loading={loading}>
              Agregar
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

export default NewFreeExecutiveModal;
