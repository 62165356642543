// Importar librerías
import React, { useContext } from 'react';
import { Switch, Route } from 'react-router-dom';

// Importar utilidades y constantes
import { withAuthorization } from '../../utils';
import { bankGeneralUserCondition } from '../../constants/conditions';

// Importar context de user
import { AuthUserContext } from '../../context';

// Importar rutas
import * as ROUTES from '../../constants/routes';

// Importar otros componentes
import ApplicationList from './ApplicationList';
import ApplicationItem from './ApplicationItem';

const ApplicationsPage = () => {
  // Importar context de user
  const authUser = useContext(AuthUserContext);

  // Renderizar componente
  return (
    <div>
      <Switch>
        <Route
          exact
          path={ROUTES.APPLICATIONS_PAGE}
          render={(props) => <ApplicationList {...props} authUser={authUser} />}
        />
        <Route
          exact
          path={ROUTES.APPLICATION_PAGE}
          render={(props) => <ApplicationItem {...props} authUser={authUser} />}
        />
      </Switch>
    </div>
  );
};

export default withAuthorization(bankGeneralUserCondition)(ApplicationsPage);
