// Importar librerías
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Divider, Table, Tag } from 'antd';
import { useCollection } from 'react-firebase-hooks/firestore';

// Importar utilidades
import { withFirebase } from '../../utils';

// Importar hooks
import useWindowSize from '../../hooks/useWindowSize';

// TODO: Definir para que sirve esta función
const numberWithPoints = (x) => {
  return x.toLocaleString();
};

/**
 *
 * @param {*} record
 * @param {*} statuses
 * Devuelve el valor del estado de una solicitud
 */
const rowClassNameCalc = (record, statuses) => {
  const status = statuses[record.id];

  if (!status) return 'closed';
  if (status.isOpen) return 'open';
  return 'closed';
};

// Lista de todas las solicitudes para que un ejecutivo vea que tiene asignado.
// Duran solamente el tiempo que estan activas
const ApplicationList = ({ authUser, firebase, ...rest }) => {
  // Definir state
  const [statuses, setStatuses] = useState({});

  // Obtener información de la BD de las solicitudes
  const [assignedApplications, loadingApplications, errorLoading] = useCollection(
    firebase.db
      .collection('applications')
      .where('executives', 'array-contains', {
        bankName: authUser.bankName,
        bankOfficeName: authUser.bankOfficeName,
        email: authUser.email,
        id: authUser.uid,
      })
      .where('status', '==', 'open'),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  // Definir effect para setear el estatus de una solicitud
  useEffect(() => {
    if (errorLoading || !assignedApplications) return;

    // Filtrar solicitudes del tipo que maneja el ejecutivo actual
    let newApplications = { docs: [] };
    newApplications.docs = assignedApplications.docs.filter((item) =>
      authUser.bankOfficeAppTypes.includes(item.get('type'))
    );

    // Setear status de las solicitudes
    const auxStatuses = {};
    newApplications.docs.forEach((application) => {
      const appId = application.id;

      const isOpen = application.get('endDate').toDate().getTime() > Date.now();

      // No hay winner, null
      // Hay winner, true o false
      const isWinner = application.get('winner')
        ? application.get('winner').bankName === authUser.bankName
        : null;

      // Undefined o {}
      const currentAnswer = application
        .get('answers')
        .find(
          (d) =>
            d.bankName === authUser.bankName &&
            d.bankOfficeName === authUser.bankOfficeName
        );

      const answerStatus = currentAnswer ? currentAnswer.status : null;

      auxStatuses[appId] = { isOpen, answerStatus, isWinner };
    });
    setStatuses(auxStatuses);
  }, [assignedApplications, authUser, errorLoading]);

  // Obtener tamaño de la ventana
  const windowWidth = useWindowSize();

  // Definir columnas de la tabla
  const columns = [
    {
      title: 'SOLICITUD',
      key: 'id',
      render: (doc) => (
        <Link id="link" to={`/applications/${doc.id}`}>
          Ver
        </Link>
      ),
      show: true,
    },
    {
      title: 'USUARIO',
      key: 'name',
      render: (doc) =>
        doc.data().user
          ? `${doc.data().user.firstName} ${doc.data().user.lastName}`
          : 'No name',
      sorter: (a, b) => a.get('user').lastName.localeCompare(b.get('user').lastName),
      show: true,
    },
    {
      title: 'MONTO DEL CRÉDITO',
      key: 'credito',
      render: (doc) => {
        const type = doc.get('type');
        switch (type) {
          case 'portabilidad-hipotecario':
          case 'hipotecario':
            return `UF ${numberWithPoints(Math.round(doc.get('credito')))}`;
          case 'portabilidad-consumo':
          case 'consumo':
            return `CLP ${numberWithPoints(Math.round(doc.get('credito')))}`;
          case 'automotriz':
            return `$ ${numberWithPoints(Math.round(doc.get('credito')))}`;
          default:
            return <p>-</p>;
        }
      },
      sorter: (a, b) => a.get('credito') - b.get('credito'),
      show: true,
    },
    {
      title: 'FECHA CREACIÓN',
      key: 'date',
      render: (doc) => doc.data().createdAt.toDate().toISOString().substring(0, 10),
      sorter: (a, b) => {
        return a.get('createdAt').toDate() - b.get('createdAt').toDate();
      },
      defaultSortOrder: 'descend',
      show: windowWidth < 768 ? false : true,
    },
    {
      title: 'TIPO DE CRÉDITO',
      key: 'type',
      render: (doc) => {
        const type = doc.get('type');
        switch (type) {
          case 'portabilidad-hipotecario':
            return <p>Portabilidad Hipotecario</p>;
          case 'hipotecario':
            return <p>Hipotecario</p>;
          case 'portabilidad-consumo':
            return <p>Portabilidad Consumo</p>;
          case 'consumo':
            return <p>Consumo</p>;
          case 'automotriz':
            return <p>Automotriz</p>;
          default:
            return <p>Default</p>;
        }
      },
      sorter: (a, b) => (a.get('type') || '').localeCompare(b.get('type') || ''),
      filters: [
        {
          text: 'Portabilidad Hipotecario',
          value: 'portabilidad-hipotecario',
        },

        {
          text: 'Hipotecario',
          value: 'hipotecario',
        },
        {
          text: 'Portabilidad Consumo',
          value: 'portabilidad-consumo',
        },
        {
          text: 'Consumo',
          value: 'consumo',
        },
        {
          text: 'Automotriz',
          value: 'automotriz',
        },
        {
          text: 'Default',
          value: 'default',
        },
      ],
      onFilter: (value, doc) => (doc.get('type') || 'default') === value,
      show: true,
    },
    {
      title: 'ESTADO',
      key: 'RealStatus',
      render: (doc) => {
        if (!statuses[doc.id]) return 'loading';
        const { isOpen, answerStatus, isWinner } = statuses[doc.id];

        if (isOpen) {
          switch (answerStatus) {
            case 'offer':
              return (
                <Tag color={'#E3D7FE'} className={'purple'}>
                  Ofertado
                </Tag>
              );

            case 'noOffer':
              return (
                <Tag color={'#FBD3DF'} className={'red'}>
                  Rechazada
                </Tag>
              );

            // Sin oferta aun
            default:
              return (
                <Tag color={'#E3D7FE'} className={'purple'}>
                  En Evaluación
                </Tag>
              );
          }
        } else {
          if (!answerStatus || answerStatus === 'noOffer') {
            return (
              <Tag color={'#FBD3DF'} className={'red'}>
                Expirada
              </Tag>
            );
          } else {
            switch (isWinner) {
              case true:
                return (
                  <Tag color={'#B3EFD3'} className={'green'}>
                    Otorgada
                  </Tag>
                );

              case false:
                return (
                  <Tag color={'#FBD3DF'} className={'red'}>
                    Rechazada
                  </Tag>
                );

              case null:
                return (
                  <Tag color={'#E3D7FE'} className={'purple'}>
                    Ofertado
                  </Tag>
                );
              default:
                break;
            }
          }
        }
      },
      show: true,
    },
  ];

  // Renderizar componente
  return (
    <div className="applications-list">
      <h1 id="assign-title">
        Solicitudes asignadas a {authUser.firstName} {authUser.lastName}
      </h1>
      <Divider />
      {errorLoading && <strong>errorLoading: {JSON.stringify(errorLoading)}</strong>}
      {!errorLoading && (
        <div className="applications-table">
          <Table
            rowClassName={(record) => rowClassNameCalc(record, statuses)}
            columns={columns.filter((item) => item.show)}
            dataSource={
              loadingApplications
                ? []
                : assignedApplications.docs
                    .filter((item) =>
                      authUser.bankOfficeAppTypes.includes(item.get('type'))
                    )
                    .filter((d) => {
                      let lastWeekDate = new Date();
                      lastWeekDate.setDate(lastWeekDate.getDate() - 6);
                      return d.get('endDate').toDate() >= lastWeekDate;
                    })
            }
            rowKey="id"
            loading={loadingApplications}
            pagination={true}
            size={windowWidth < 992 ? 'small' : 'large'}
          />
        </div>
      )}
    </div>
  );
};

export default withFirebase(ApplicationList);
