// Importar librerías
import React, { useContext, useEffect, useState } from 'react';
import { Upload, Form, Button, message, Tooltip } from 'antd';
import { ReactComponent as DraggerIcon } from './../assets/dragger.svg';
import moment from 'moment';

// Importar context
import { FirebaseContext, AuthUserContext } from './../../../context';

// Importar otros componentes
import ConsumoTable from './Tables/ConsumoTable';

/**
 *
 * @param {*} application
 * @param {*} executiveUser
 * Obtiene la información del banco en caso de que exista.
 */
const getBankAnswer = (application, executiveUser) => {
  // Devolver null en caso de que la aplicación no esté definida
  if (!application) return null;

  // Obtener arreglo de respuestas de la aplicación y devolver null si es vacío
  const answers = application.get('answers');
  if (!answers) return null;

  // Definir filtrados para obtener la respuesta del ejecutivo actual
  const bankName = executiveUser.bankName;
  const bankOfficeName = executiveUser.bankOfficeName;
  const executiveId = executiveUser.uid;

  // Filtrar y devolver null en caso de no haber respuesta
  const bankAnswer = answers.find(
    (d) =>
      d.bankName === bankName &&
      d.bankOfficeName === bankOfficeName &&
      d.executiveId === executiveId
  );
  if (!bankAnswer) return null;

  // Devolver respuesta
  return bankAnswer;
};

/**
 *
 * @param {*} data1
 * @param {*} data2
 * @param {*} fields
 * Valida de la información de los campos de dos datas distintas sean los mismos.
 */
const sameFields = (data1, data2, fields) => {
  for (const field of fields) {
    if (field === 'primerVencimiento' || field === 'fechaFirma') {
      if (data1[field].getTime() !== data2[field].getTime()) return false;
    } else {
      if (data1[field] !== data2[field]) return false;
    }
  }

  return true;
};

/**
 *
 * @param {*} endDate
 * Descomponente la información d euna fecha en días, horas y minutos.
 */
const getRemainingTime = (endDate) => {
  const now = moment(Date.now());
  const momentEndDate = moment(endDate.toDate());

  const days = momentEndDate.diff(now, 'days');
  const hours = momentEndDate.diff(now, 'hours') - days * 24;
  const minutes = momentEndDate.diff(now, 'minutes') - days * 24 * 60 - hours * 60;
  return { days, hours, minutes };
};

/**
 *
 * @param {*} currentBankAnswer
 * Devuelve el estatus de la respuesta del banco en caso de que exista.
 */
const statusCalc = (currentBankAnswer) => {
  if (!currentBankAnswer) return null;
  else return currentBankAnswer.status;
};

const ApplicationAnswerConsumo = ({ application, canEdit }) => {
  // Obtener información del context de fireabse y user
  const firebase = useContext(FirebaseContext);
  const authUser = useContext(AuthUserContext);

  // Definir state
  const [editAnswerMode, setEditAnswerMode] = useState(true); // True si se pueden editar/agregar respuestas

  // null o la respuesta
  const [currentBankAnswer, setCurrentBankAnswer] = useState(null);
  const [currentFileAnswer, setCurrentFileAnswer] = useState([]);
  const [oldFileAnswer, setOldFileAnswer] = useState([]);

  // null, offer, noOffer
  const [currentStatus, setCurrentStatus] = useState(null);

  // Definir nueva instancia de useForm y obtener subcomponente Dragger
  const [answerForm] = Form.useForm();
  const { Dragger } = Upload;

  // Inicialmente el switch depende del status. Por defecto solo es true si no hay respuesta
  const aux = getBankAnswer(application, authUser);
  const aux2 = statusCalc(aux);
  const [editModeSwitch, setEditModeSwitch] = useState(!aux2);

  const remainingTime = getRemainingTime(application.get('endDate'));

  // Definir effect para setear la información con la respuesta del banco
  useEffect(() => {
    const aux = getBankAnswer(application, authUser);
    setCurrentBankAnswer(aux);
  }, [application, authUser]);

  // Definir effect para setear la información con el status de la respuesta del banco
  useEffect(() => {
    setCurrentStatus(statusCalc(currentBankAnswer));
  }, [currentBankAnswer]);

  // Definir effect para setear los cambios en la información
  useEffect(() => {
    setCurrentFileAnswer(oldFileAnswer);
  }, [editAnswerMode, oldFileAnswer]);

  // Definir effect para obtener la información del banco en caso de que haya
  // una oferta
  useEffect(() => {
    const loadBankAnswerFiles = async () => {
      if (currentBankAnswer && currentBankAnswer.status === 'offer') {
        const { answerFiles } = currentBankAnswer.answer;
        const currentFiles = answerFiles.map(firebase.getDownloadURL);
        const aux = await Promise.all(currentFiles.map((p) => p.catch((e) => null)));
        const aux2 = aux.map((d, i) => {
          return {
            uid: d,
            name: answerFiles[i].split(application.id + '/')[1],
            status: 'done',
            url: d,
            old: true,
            oldVoucher: answerFiles[i],
          };
        });

        setOldFileAnswer(aux2);
        setCurrentFileAnswer(aux2);
      }
    };
    loadBankAnswerFiles();
  }, [application.id, setOldFileAnswer, firebase.getDownloadURL, currentBankAnswer]);

  // Definir effect para limpiar los campos del formulario de respuesta en caso
  // de que el estatus de la respuesta sea offer
  useEffect(() => {
    if (currentStatus === 'offer') {
      answerForm.resetFields();
    }
  }, [answerForm, currentBankAnswer, currentStatus]);

  // Definir effect para setear el modo de edición
  useEffect(() => {
    if (currentStatus === 'noOffer') {
      // Si esta rechazada no puede haber edicion
      setEditAnswerMode(false);
    } else if (currentStatus === 'offer') {
      // Depende del switch
      setEditAnswerMode(editModeSwitch);
    } else {
      setEditAnswerMode(true);
    }
  }, [currentStatus, editModeSwitch]);

  /**
   *
   * @param {*} answerValues
   * @param {*} applicationDoc
   * Añade o edita una respuesta generada por el banco en función a la cotización actual.
   */
  const onAnswer = (answerValues, applicationDoc) => {
    // Revisamos si no tenia una respuesta de un banco

    answerValues.answerFile = currentFileAnswer;

    if (currentFileAnswer.length === 0) {
      message.error('Agregue los archivos correspondientes');
      return false;
    }

    const oldAnswer = getBankAnswer(applicationDoc, authUser);

    if (!oldAnswer) {
      firebase.applicationLogic
        .addAnswer2(authUser, applicationDoc, answerValues)
        .then(() => {
          message.success('Respuesta añadida correctamente');
          message.info(
            `En ${
              remainingTime.days * 24 + remainingTime.hours
            } horas, se le mandara tu oferta al usuario, y este tendrá  72 horas para aceptarla`,
            30
          );

          setEditModeSwitch(false);
        })
        .catch((err) => {
          message.error(err.message);
        });
    } else {
      // Editando respuesta
      const oldAnswers = oldAnswer.answer;
      const { status, ...newAnswers } = answerValues;

      const same = sameFields(oldAnswers, newAnswers, Object.keys(oldAnswers));
      if (same) {
        message.error('No hay cambios');
        return;
      } else {
        firebase.applicationLogic
          .editAnswer(authUser, applicationDoc, oldAnswer, answerValues)
          .then(() => {
            message.success('Respuesta editada correctamente');
            message.info(
              `En ${
                remainingTime.days * 24 + remainingTime.hours
              } horas, se le mandara tu oferta al usuario, y este tendrá 72 horas para aceptarla`,
              30
            );
            setEditModeSwitch(false);
          })
          .catch((err) => {
            message.error(err.message);
          });
      }
    }
  };

  /**
   *
   * @param {*} applicationDoc
   * @param {*} authUser
   * Elimina una respuesta generada por el banco en función a la cotización actual.
   */
  const removeAnswer = (applicationDoc, authUser) => {
    const currentBankAnswer = getBankAnswer(applicationDoc, authUser);

    firebase.applicationLogic
      .removeAnswer(authUser, applicationDoc.id, currentBankAnswer)
      .then(() => {
        message.success('Respuesta removida correctamente');
        setCurrentFileAnswer([]);
        setOldFileAnswer([]);
      })
      .catch((err) => {
        message.error(err.message);
      });
  };

  // TODO: Validar qué hace esta función.
  const answerNoOffer = (applicationDoc, authUser) => {
    const hasAnswer = getBankAnswer(applicationDoc, authUser);
    if (!hasAnswer) {
      firebase.applicationLogic
        .addNoOfferAnswer(authUser, applicationDoc.id)
        .then(() => {
          message.success('Respuesta añadida correctamente');
        })
        .catch((err) => {
          message.error(err.message);
        });
    }
  };

  // Renderizar componente
  return (
    <Form
      onFinish={(values) => onAnswer({ ...values, status: 'offer' }, application)}
      initialValues={currentBankAnswer ? currentBankAnswer.answer : {}}
      form={answerForm}
      onFinishFailed={() => message.error('Rellenar campos faltantes')}
    >
      <div id={'answer-container'}>
        {currentStatus === 'noOffer' && (
          <div className={'rejected-answer'}>
            Ha rechazado esta solicitud, para deshacerlo, vaya abajo y elija la
            opción de eliminar respuesta actual
          </div>
        )}
        <div className={'main-answer'}>
          <ConsumoTable disabled={!canEdit || !editAnswerMode} />
        </div>
        <div className={'secondary-answer'}>
          <div className={'answer-file'}>
            <p>Respuesta en formato PDF:</p>
            <Form.Item>
              <Dragger
                disabled={!canEdit || !editAnswerMode}
                multiple={false}
                beforeUpload={(file, fileList) => false}
                onChange={(d) => {
                  console.log('file', d.file);
                  console.log('fileList', d.fileList);

                  if (d.file.status === 'removed') {
                    setCurrentFileAnswer((old) =>
                      old.filter((j) => j.name !== d.file.name)
                    );
                  } else {
                    setCurrentFileAnswer((old) => [...old, d.file]);
                  }
                }}
                fileList={currentFileAnswer}
              >
                <p className="ant-upload-drag-icon">
                  <DraggerIcon />
                </p>
                <p className="ant-upload-text">
                  Arrastra tu archivo o haz click aquí para adjuntar
                </p>
              </Dragger>
            </Form.Item>
          </div>
        </div>
        {canEdit && (
          <div>
            <div className={'buttons'}>
              <Button
                disabled={!canEdit || !currentStatus}
                onClick={() => removeAnswer(application, authUser)}
              >
                Reestablecer
              </Button>
              <Tooltip
                title={
                  !currentStatus
                    ? ''
                    : currentStatus === 'noOffer'
                    ? '¡Ya rechazó esta solicitud!'
                    : 'Para rechazar esta solicitud, debe eliminar la respuesta actual primero'
                }
              >
                <Button
                  className={'reject'}
                  disabled={!canEdit || !!currentStatus}
                  onClick={() => answerNoOffer(application, authUser)}
                >
                  Rechazar
                </Button>
              </Tooltip>
              <Form.Item>
                <Button
                  className={'send'}
                  htmlType="submit"
                  disabled={!canEdit || !editAnswerMode}
                >
                  {currentBankAnswer && currentBankAnswer.status === 'offer'
                    ? 'Ofertar'
                    : 'Ofertar'}
                </Button>
              </Form.Item>
              {currentStatus === 'offer' && (
                <Button
                  onClick={() => setEditModeSwitch((old) => !old)}
                  disabled={!canEdit}
                >
                  {editModeSwitch ? 'Dejar de editar' : 'Editar'}
                </Button>
              )}
            </div>
            <div className={'terms'}>
              <a
                href={
                  process.env.PUBLIC_URL +
                  '/TERMINOS Y CONDICIONES ASOCIADOS AL USO DE FLIT.pdf'
                }
              >
                Al enviar una oferta estoy aceptando los términos y condiciones de
                Flit
              </a>
            </div>
          </div>
        )}
      </div>
    </Form>
  );
};

export default ApplicationAnswerConsumo;
