// Importar librerías
import React, { useState, useContext, useEffect } from 'react';
import { compose } from 'recompose';
import { Table, Button, Divider, Tooltip } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useCollection } from 'react-firebase-hooks/firestore';

// Importar utilidades y constantes
import { withFirebase, withAuthorization } from '../../utils';
import { bankAdminUserCondition } from '../../constants/conditions';
import { appOptionsExecutive } from '../../constants/appTypes';

// Importar context de firebase
import { FirebaseContext, AuthUserContext } from '../../context';

// Importar otros componentes
import NewExecutiveModal from './NewExecutiveModal';
import NewFreeExecutiveModal from './NewFreeExecutiveModal';
import ShowExecutiveModal from './ShowExecutiveModal';
import DeleteExecutiveModal from './DeleteExecutiveModal';
import { ReactComponent as DeleteIcon } from './icons/delete-24px.svg';

// Importar hooks
import useWindowSize from '../../hooks/useWindowSize';

/**
 *
 * @param {*} word
 * Retorna una cadena con la primera letra en mayúscula.
 */
const capitalize = (word) => {
  let newWord = '';
  word.split(' ').forEach((item) => {
    newWord += item[0].toUpperCase() + item.slice(1) + ' ';
  });
  return newWord;
};

const ExecutiveList = () => {
  // Definir context de firebase
  const firebase = useContext(FirebaseContext);
  const authUser = useContext(AuthUserContext);

  // Definir state
  const [openNewExecutiveModal, setOpenNewExecutiveModal] = useState(false);
  const [openFreeExecutiveModal, setOpenFreeExecutiveModal] = useState(false);
  const [openShowExecutiveModal, setOpenShowExecutiveModal] = useState(false);
  const [openDeleteExecutiveModal, setOpenDeleteExecutiveModal] = useState(false);
  const [selectedExecutive, setSelectedExecutive] = useState({});
  const [bankOfficeExecutives, setBankOfficeExecutives] = useState(null);
  const [bankOfficeFreeExecutives, setBankOfficeFreeExecutives] = useState(null);
  const [appOptions, setAppOptions] = useState([]);

  // Obtener información de los ejecutivos de la BD
  const [executives, loading] = useCollection(
    firebase.executiveLogic.executives(authUser.bankId, authUser.bankOfficeId),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  // Definir effect para setear la información de los ejecutivos
  useEffect(() => {
    if (executives) {
      // Definir arreglos para ejecutivos libres asociados al administrador actual
      let executivesAvailable = [];
      let freeExecutivesAvailable = [];

      // Obtener información d elos ejecutivos según sea el caso
      executives.docs.forEach((item) => {
        if (item.get('bankOfficeName')) {
          if (item.get('bankOfficeBoss') === authUser.email) {
            executivesAvailable.push(item);
          }
        } else {
          if (item.get('bankOfficeName') === '') {
            freeExecutivesAvailable.push(item);
          }
        }
      });
      // Setear valores
      if (executivesAvailable.length > 0) {
        setBankOfficeExecutives(executivesAvailable);
      }
      if (freeExecutivesAvailable.length > 0) {
        setBankOfficeFreeExecutives(freeExecutivesAvailable);
      }
    }

    if (authUser) {
      let options = [];
      if (authUser.bankOfficeAppTypes) {
        authUser.bankOfficeAppTypes.forEach((type) =>
          options.push(appOptionsExecutive[type])
        );
      }
      setAppOptions(options);
    }
  }, [authUser, executives]);

  // Obtener tamaño de la ventana
  const windowWidth = useWindowSize();

  // Definir columnas de la tabla
  const columns = [
    {
      title: 'NOMBRE',
      dataIndex: 'firstName',
      key: 'firstName',
      render: (_, record) => record.get('firstName'),
      sorter: (a, b) => a.get('firstName').localeCompare(b.get('firstName')),
      show: true,
    },
    {
      title: 'APELLIDO',
      dataIndex: 'lastName',
      key: 'lasttName',
      render: (_, record) => record.get('lastName'),
      sorter: (a, b) => a.get('lastName').localeCompare(b.get('lastName')),
      show: true,
    },
    {
      title: 'EMAIL',
      dataIndex: 'email',
      key: 'email',
      render: (_, record) => record.get('email'),
      sorter: (a, b) => a.get('email').localeCompare(b.get('email')),
      show: windowWidth < 768 ? false : true,
    },
    {
      title: 'SOLICITUDES ASIGNADAS',
      dataIndex: 'applicationCount',
      key: 'applicationCount',
      render: (_, record) => {
        const assigned = record.get('assignedApplications') || [];
        return assigned.length;
      },
      sorter: (a, b) => {
        const assignedA = a.get('assignedApplications') || [];
        const assignedB = b.get('assignedApplications') || [];
        return assignedA.length - assignedB.length;
      },
      show: true,
    },
    {
      title: 'SOLICITUDES MANEJADAS',
      dataIndex: 'applicationTypes',
      key: 'applicationTypes',
      render: (_, record) => {
        if (!record.get('bankOfficeAppTypes')) {
          return '-';
        }
        return record
          .get('bankOfficeAppTypes')
          .map((item) => capitalize(item.replace('-', ' ')))
          .join(' / ');
      },
      sorter: (a, b) => {
        let appTypeA = '-';
        let appTypeB = '-';
        if (a.get('bankOfficeAppTypes')) {
          appTypeA = a
            .get('bankOfficeAppTypes')
            .map((item) => capitalize(item.replace('-', ' ')))
            .join(' / ');
        }
        if (b.get('bankOfficeAppTypes')) {
          b.get('bankOfficeAppTypes')
            .map((item) => capitalize(item.replace('-', ' ')))
            .join(' / ');
        }
        return appTypeA.localeCompare(appTypeB);
      },
      show: windowWidth < 768 ? false : true,
    },
    {
      title: 'ACCIONES',
      key: 'actions',
      render: (_, record) => {
        return (
          <div className="actions-container">
            <span id={'ver'} onClick={() => setFields(record, true)}>
              Ver
            </span>
            <Tooltip title={'Eliminar ejecutivo de este banco'}>
              <span id={'eliminar'} onClick={() => setFields(record, false)}>
                <DeleteIcon />
              </span>
            </Tooltip>
          </div>
        );
      },
      show: true,
    },
  ];

  /**
   *
   * @param {*} record
   * @param {*} bool
   * Setea los valores para los campos selectedExecutive y openShowExecutiveModal o
   * openDeleteExecutiveModal según sea el caso.
   */
  const setFields = (record, bool) => {
    bool
      ? setSelectedExecutive(record.data())
      : setSelectedExecutive({ ...record.data(), executiveId: record.id });
    bool ? setOpenShowExecutiveModal(true) : setOpenDeleteExecutiveModal(true);
  };

  // Renderizar componente
  return (
    <>
      <div id="executives-head">
        <h1>
          Ejecutivos del {authUser.bankName}{' '}
          {authUser.bankOfficeName ? `(${authUser.bankOfficeName})` : ''}
        </h1>
        <div className="content-buttons">
          <Button
            icon={<PlusOutlined />}
            type={'primary'}
            onClick={() => setOpenNewExecutiveModal(true)}
          >
            Nuevo
          </Button>
          {(authUser.bankOfficeName || authUser.bankOfficeName === '') && (
            <Button
              icon={<PlusOutlined />}
              type={'primary'}
              className="last-button"
              onClick={() => setOpenFreeExecutiveModal(true)}
            >
              Elegir Libre
            </Button>
          )}
        </div>
      </div>
      <NewExecutiveModal
        openNewExecutiveModal={openNewExecutiveModal}
        setOpenNewExecutiveModal={setOpenNewExecutiveModal}
        appOptions={appOptions}
      />
      {(authUser.bankOfficeName || authUser.bankOfficeName === '') && (
        <NewFreeExecutiveModal
          openFreeExecutiveModal={openFreeExecutiveModal}
          setOpenFreeExecutiveModal={setOpenFreeExecutiveModal}
          openModalRecord={bankOfficeFreeExecutives}
          authUser={authUser}
          appOptions={appOptions}
        />
      )}
      <ShowExecutiveModal
        openShowExecutiveModal={openShowExecutiveModal}
        setOpenShowExecutiveModal={setOpenShowExecutiveModal}
        openModalRecord={selectedExecutive}
      />
      <DeleteExecutiveModal
        openDeleteExecutiveModal={openDeleteExecutiveModal}
        setOpenDeleteExecutiveModal={setOpenDeleteExecutiveModal}
        openModalRecord={selectedExecutive}
      />
      <Divider />
      <div id="executives-main-table">
        <Table
          loading={loading}
          columns={columns.filter((item) => item.show)}
          pagination={false}
          dataSource={
            bankOfficeExecutives
              ? bankOfficeExecutives
              : executives
              ? executives.docs
              : []
          }
          size={windowWidth < 768 ? 'small' : 'large'}
          rowKey={(r) => r.id}
        />
      </div>
    </>
  );
};

export default compose(
  withFirebase,
  withAuthorization(bankAdminUserCondition)
)(ExecutiveList);
