// Importar librería
import React, { useState, useContext } from 'react';
import { Modal, Button, Form, message } from 'antd';

// Importar context de firebase y user
import { FirebaseContext, AuthUserContext } from './../../../context';

// Importar hooks
import useWindowSize from '../../../hooks/useWindowSize';

const ReportModal = ({ openModal, setOpenModal, application }) => {
  // Definir state
  const [loading, setLoading] = useState(false);

  // Definir context de firebase y user
  const firebase = useContext(FirebaseContext);
  const authUser = useContext(AuthUserContext);

  // Definir nueva instancia de useForm
  const [form] = Form.useForm();

  // Obtener tamaño de la ventana
  const windowWidth = useWindowSize();

  /**
   * Permite cerrar un modal.
   */
  const handleCancel = () => {
    setOpenModal(false);
  };

  /**
   *
   * Enviar correo con la información de la solicitud aceptada.
   */
  const onFinish = () => {
    setLoading(true);
    const emailBody = `

    Un ejecutivo del ${authUser.bankName} esta teniendo problemas con la solicitud ${application.id}
    Datos del ejecutivo:
      - Nombre: ${authUser.firstName} ${authUser.lastName}
      - Email: ${authUser.email}

    `;

    return firebase.functions
      .httpsCallable('reportProblemMailer')({
        applicationId: application.id,
        body: emailBody,
      })
      .then((res) => {
        setLoading(false);
        setOpenModal(false);

        message.success('Mensaje enviado con exito');
      })
      .catch((e) => {
        setLoading(false);
        message.error('Intente nuevamente');
      });
  };

  /**
   *
   * @param {*} errorInfo
   * Maneja posibles errores al momento de rechazar una solicitud.
   */
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
    message.error('Error');
  };

  // Renderizar componente
  return (
    <Modal
      visible={openModal}
      onOk={onFinish}
      confirmLoading={loading}
      onCancel={handleCancel}
      width={windowWidth < 768 ? 350 : 520}
      footer={
        <div id="report-modal-footer">
          <Button
            loading={loading}
            form="modal-new-report-form"
            key="submit"
            htmlType="submit"
            type={'primary'}
          >
            Aceptar
          </Button>
        </div>
      }
    >
      <div id="report-new-modal-body">
        <h3>Reportar un problema</h3>
        <p>
          Si la información que has recibido no es correcta o estás teniendo
          problemas con tu cliente, nos comunicaremos contigo a la brevedad
        </p>

        <Form
          name="report_form"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          id="modal-new-report-form"
          form={form}
          layout="vertical"
          hideRequiredMark={true}
        ></Form>
      </div>
    </Modal>
  );
};

export default ReportModal;
