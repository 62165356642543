// Importar librerías
import React, { useContext } from 'react';
import { Switch, Route } from 'react-router-dom';

// Importar utilidades y constantes
import { withAuthorization } from '../../utils';
import { bankGeneralUserCondition } from '../../constants/conditions';

// Importar context de user
import { AuthUserContext } from '../../context';

// Importar rutas
import * as ROUTES from '../../constants/routes';

// Importar otros componentes
import RequirementsList from './RequirementsList';

const RequirementsPage = () => {
  // Definir context de user
  const authUser = useContext(AuthUserContext);

  // Renderizar componente
  return (
    <>
      <Switch>
        <Route
          exact
          path={ROUTES.REQUIREMENTS_PAGE}
          render={(props) => <RequirementsList {...props} authUser={authUser} />}
        />
      </Switch>
    </>
  );
};

export default withAuthorization(bankGeneralUserCondition)(RequirementsPage);
