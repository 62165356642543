// *** Application API ***
import firebase from 'firebase';
class applicationLogic {
  constructor(db, fieldValue, storage, executiveLogic) {
    this.db = db;
    this.fieldValue = fieldValue;
    this.executiveLogic = executiveLogic;
    this.storage = storage;
  }

  application = (uid) => this.db.doc(`applications/${uid}`);

  allApplications = () => {
    const aux = firebase.firestore.Timestamp.now();
    return this.db
      .collection('applications')
      .where('endDate', '>', aux)
      .orderBy('endDate', 'desc');
  };

  /**
   *
   * @param {*} data
   * Asigna una solicitud a un ejecutivo.
   */
  assignExecutive = async (data) => {
    try {
      // Obtener valores de entrada
      const {
        bankName,
        bankOfficeName,
        selectedExecutive,
        assignApplication,
      } = data;

      // Obtener información del ejecutivo seleccionado
      const querySnapshotExecutive = await this.db
        .collection('users')
        .where('email', '==', selectedExecutive)
        .get();
      const executiveDoc = querySnapshotExecutive.docs[0];

      // Actualizar información del ejecutivo sobre las solicitudes asignadas
      const addApplicationToExecutivePromise = this.db
        .doc(`users/${executiveDoc.id}`)
        .update({
          assignedApplications: this.fieldValue.arrayUnion(assignApplication.id),
        });

      // Actualizar información de los ejecutivos de la solicitud
      const addExecutiveToApplicationPromise = this.application(
        assignApplication.id
      ).update({
        executives: this.fieldValue.arrayUnion({
          bankName,
          bankOfficeName,
          email: selectedExecutive,
          id: executiveDoc.id,
        }),
      });

      // Devolver todas las promesas
      return Promise.all([
        addApplicationToExecutivePromise,
        addExecutiveToApplicationPromise,
      ]);
    } catch (error) {
      console.log(error);
      return Promise.reject(
        new Error(
          'Hubo un error al intentar asignar al ejecutivo en la base de datos'
        )
      );
    }
  };

  /**
   *
   * @param {*} bankName
   * @param {*} bankOfficeName
   * @param {*} assignApplication
   * @param {*} selectedExecutive
   * Desagina una solicitud a un ejecutivo
   */
  unassignExecutive = (data) => {
    try {
      // Obtener valores de entrada
      const {
        bankName,
        bankOfficeName,
        assignApplication,
        selectedExecutive,
      } = data;

      // Actualizar arreglo de los ejecutivos de la aplicacion
      const removeExecutivePromise = this.db
        .doc(`applications/${assignApplication.id}`)
        .update({
          executives: this.fieldValue.arrayRemove({
            bankName,
            bankOfficeName,
            email: selectedExecutive.executiveEmail,
            id: selectedExecutive.executiveId,
          }),
        });

      // Actualizar información del ejecutivo y eliminar la solicitud
      const removeApplicationPromise = this.db
        .doc(`users/${selectedExecutive.executiveId}`)
        .update({
          assignedApplications: this.fieldValue.arrayRemove(assignApplication.id),
        });

      // Devolver información de las promesas
      return Promise.all([removeApplicationPromise, removeExecutivePromise]);
    } catch (error) {
      console.log(error);
      return Promise.reject(
        new Error(
          'Hubo un error al intentar desasignar al ejecutivo en la base de datos'
        )
      );
    }
  };

  executiveApplications = (asignedApplications) => {
    let applicationPromises = [];
    for (const applicationId of asignedApplications) {
      applicationPromises.push(this.application(applicationId).get());
    }
    return Promise.all(applicationPromises);
  };

  addOfferAnswer = (executiveUser, applicationId, answer) => {
    const dbAnswer = {
      bankName: executiveUser.bankName,
      bankOfficeName: executiveUser.bankOfficeName,
      status: 'offer',
      executiveId: executiveUser.uid,
      answer,
    };

    const applicationRef = this.application(applicationId);

    return applicationRef.update({
      answers: this.fieldValue.arrayUnion(dbAnswer),
    });
  };

  addChangeRequest = (bankName, applicationDoc, title, changeText) => {
    const applicationRef = this.application(applicationDoc.id);

    const currentRequestedChanges = applicationDoc
      .get('requestedChanges')
      .find((d) => d.bankName === bankName);

    if (!currentRequestedChanges) {
      return applicationRef.update({
        requestedChanges: this.fieldValue.arrayUnion({
          bankName,
          changes: [
            { createdAt: firebase.firestore.Timestamp.now(), title, changeText },
          ],
        }),
      });
    } else {
      return applicationRef
        .update({
          requestedChanges: this.fieldValue.arrayRemove(currentRequestedChanges),
        })
        .then(() => {
          return applicationRef.update({
            requestedChanges: this.fieldValue.arrayUnion({
              bankName,
              changes: [
                ...currentRequestedChanges.changes,
                { createdAt: firebase.firestore.Timestamp.now(), title, changeText },
              ],
            }),
          });
        });
    }
  };

  addAnswer = (authUser, applicationDoc, answerValues) => {
    const { status, ...rest } = answerValues;
    if (!status || (status !== 'offer' && status !== 'awaitingChanges')) {
    }

    const {
      tasa,
      mesesGracia,
      fechaFirma,
      primerVencimiento,
      tipoTasa,
      tipoDividendo,

      plazo2,
      tasa2,
      dividendo1,
      dividendo2,
      seguroIncendio1,
      seguroDesgravamen1,
      seguroTerremoto1,
      seguroIncendio2,
      seguroDesgravamen2,
      seguroTerremoto2,
      dividendoTotalCLP1,
      dividendoTotalCLP2,
      dividendoTotalUF1,
      dividendoTotalUF2,
      cae1,
      cae2,
      costoFinal1,
      costoFinal2,

      tasacionUF,
      tasacionCLP,
      gastosLegalesUF,
      gastosLegalesCLP,
      notariaUF,
      notariaCLP,
      impuestoAlMutuoUF,
      impuestoAlMutuoCLP,
      conservadorBienesRaicesUF,
      conservadorBienesRaicesCLP,
      totalUF,
      totalCLP,
      changesRequired,

      comments,
    } = rest;

    let answers = [
      tasa,
      mesesGracia,
      fechaFirma,
      primerVencimiento,
      tipoTasa,
      tipoDividendo,

      dividendo1,
      seguroIncendio1,
      seguroDesgravamen1,
      seguroTerremoto1,
      dividendoTotalUF1,
      dividendoTotalCLP1,
      cae1,
      costoFinal1,

      tasacionUF,
      tasacionCLP,
      gastosLegalesUF,
      gastosLegalesCLP,
      notariaUF,
      notariaCLP,
      impuestoAlMutuoUF,
      impuestoAlMutuoCLP,
      conservadorBienesRaicesUF,
      conservadorBienesRaicesCLP,
      totalUF,
      totalCLP,
      comments || '',
    ];
    if (tipoDividendo === 'flexible') {
      answers.push(
        ...[
          plazo2,
          tasa2,
          dividendo2,
          seguroIncendio2,
          seguroDesgravamen2,
          seguroTerremoto2,
          dividendoTotalCLP2,
          dividendoTotalUF2,
          cae2,
          costoFinal2,
        ]
      );
    }
    switch (status) {
      case 'offer':
        if (!answers.every((a) => a !== undefined)) {
          return Promise.reject({ message: 'Error interno' });
        }
        break;
      case 'awaitingChanges':
        if (!changesRequired) {
          return Promise.reject({ message: 'Error interno' });
        }
        break;

      default:
        return Promise.reject({ message: 'Error interno' });
    }

    const applicationRef = this.application(applicationDoc.id);

    const dbAnswer = {
      bankName: authUser.bankName,
      bankOfficeName: authUser.bankOfficeName,
      status: status,
      executiveId: authUser.uid,
      answer: rest,
    };

    return applicationRef.update({
      answers: this.fieldValue.arrayUnion(dbAnswer),
    });
  };

  addAnswer2 = async (authUser, applicationDoc, answerValues) => {
    const { status, ...rest } = answerValues;
    const applicationType = applicationDoc.get('type');
    let answers = [];
    let answerDb = {};
    const { answerFile } = rest;

    switch (applicationType) {
      case 'portabilidad-hipotecario':
      case 'hipotecario': {
        const {
          tasa,
          cae1,
          seguroDesgravamen1,
          seguroSismoIncendio1,
          dividendoTotalUF1,
          dividendoMensualUF,
        } = rest;

        // Para revisar parametros vacios
        answers = [
          tasa,
          cae1,
          seguroDesgravamen1,
          seguroSismoIncendio1,
          dividendoTotalUF1,
          dividendoMensualUF,
        ];
        answerDb = {
          tasa,
          cae1,
          seguroDesgravamen1,
          seguroSismoIncendio1,
          dividendoTotalUF1,
          dividendoMensualUF,
        };
        break;
      }
      case 'portabilidad-consumo':
      case 'consumo':
      case 'automotriz': {
        const { tasaMensual, cae1, cuotaMensual1, costoTotal1 } = rest;

        // Para revisar parametros vacios
        answers = [tasaMensual, cae1, cuotaMensual1, costoTotal1];
        answerDb = {
          tasaMensual,
          cae1,
          cuotaMensual1,
          costoTotal1,
        };

        break;
      }

      default:
        break;
    }

    switch (status) {
      case 'offer':
        if (!answerFile || answerFile.length === 0) {
          return Promise.reject({ message: 'Rellenar campos faltantes' });
        }
        if (!answers.every((a) => a !== undefined && a !== [])) {
          return Promise.reject({ message: 'Rellenar campos faltantes' });
        }
        break;

      default:
        return Promise.reject({ message: 'Error interno' });
    }

    const applicationRef = this.application(applicationDoc.id);

    // Upload files
    const storageRef = this.storage.ref(applicationDoc.id);
    const voucherFiles = await Promise.all(
      answerFile.map((f) => {
        // SI no es un archivo que estaba de antes
        if (!f.old) {
          const fileStorageRef = storageRef.child(f.name);
          return fileStorageRef.put(f).then((snapshot) => snapshot.ref.fullPath);
        } else {
          return f.oldVoucher;
        }
      })
    );

    const dbAnswer = {
      bankName: authUser.bankName,
      bankOfficeName: authUser.bankOfficeName,
      status: status,
      executiveId: authUser.uid,
      answer: { ...answerDb, answerFiles: voucherFiles },
    };

    return applicationRef.update({
      answers: this.fieldValue.arrayUnion(dbAnswer),
    });
  };

  removeAnswer = (executiveUser, applicationId, oldAnswer) => {
    const applicationRef = this.application(applicationId);

    return applicationRef.update({
      answers: this.fieldValue.arrayRemove(oldAnswer),
    });
  };

  editAnswer = (executiveUser, applicationDoc, oldAnswer, newAnswer) => {
    return this.removeAnswer(executiveUser, applicationDoc.id, oldAnswer).then(
      () => {
        return this.addAnswer2(executiveUser, applicationDoc, newAnswer);
      }
    );
  };

  addNoOfferAnswer = (executiveUser, applicationId) => {
    const dbAnswer = {
      bankName: executiveUser.bankName,
      bankOfficeName: executiveUser.bankOfficeName,
      status: 'noOffer',
      executiveId: executiveUser.uid,
      answer: {},
    };

    const applicationRef = this.application(applicationId);

    return applicationRef.update({
      answers: this.fieldValue.arrayUnion(dbAnswer),
    });
  };

  addChatMessage = (executiveUserId, applicationId, message) => {
    const applicationRef = this.application(applicationId);

    return applicationRef.update({
      chat: this.fieldValue.arrayUnion({
        senderId: executiveUserId,
        message,
        createdAt: firebase.firestore.Timestamp.now(),
      }),
    });
  };
  addRequirementMessage = async (
    executiveUserId,
    applicationId,
    message,
    title,
    fileVal
  ) => {
    const applicationRef = this.application(applicationId);

    let uploadFile = null;
    if (fileVal.name) {
      // Upload files
      const storageRef = this.storage.ref(applicationId);
      const fileStorageRef = storageRef.child(fileVal.name);

      const uploadPromise = fileStorageRef
        .put(fileVal.originFileObj)
        .then((snapshot) => snapshot.ref.fullPath);

      uploadFile = await uploadPromise;
    }

    return applicationRef.update({
      requirements: this.fieldValue.arrayUnion({
        answer: {},
        file: uploadFile,
        message,
        title,
        createdAt: firebase.firestore.Timestamp.now(),
      }),
    });
  };

  rejectAnswer = (applicationId, motive) => {
    const applicationRef = this.application(applicationId);
    return applicationRef.update({
      status: 'bankRejected',
      bankRejectedExplanation: motive,
    });
  };

  /**
   *
   * @param {*} content
   * Agrega la información de un requerimeinto asociado a un ejecutivo.
   */
  addCommonRequirement = async (content) => {
    // Obtener valores de entrada
    const { executiveId, message, newRequirementFile, title } = content;

    // Obtener información del ejecutivo
    const executive = await this.executiveLogic.executive(executiveId);

    // Subir archivo al servidor
    let uploadFile = null;
    if (newRequirementFile.name) {
      // Crear referencia raíz e hija
      const storageRef = this.storage.ref(executiveId);
      const fileStorageRef = storageRef.child(newRequirementFile.name);

      // Definir promesa para subir archivo
      const uploadPromise = fileStorageRef
        .put(newRequirementFile.originFileObj)
        .then((snapshot) => snapshot.ref.fullPath);

      // Subir archivo
      uploadFile = await uploadPromise;
    }

    // Definir estructura del requerimiento
    const newCommonRequirement = {
      file: uploadFile,
      message,
      title,
    };

    // Actualizar información de los requerimientos del ejecutivo
    await executive.update({
      commonRequirements: this.fieldValue.arrayUnion(newCommonRequirement),
    });

    // Devolver requerimiento
    return newCommonRequirement;
  };

  /**
   *
   * @param {*} content
   * Elimina la información de un requerimiento asociado a un ejecutivo.
   */
  deleteCommonRequirement = async (content) => {
    // Obtener valores de entrada
    const { executiveId, message, file, title } = content;

    // Obtener referencia e información del ejecutivo
    const executiveRef = await this.executiveLogic.executive(executiveId);

    // Eliminar archivo del servidor si existe
    if (file) {
      const storageRef = this.storage.ref(executiveId);
      const fileStorageRef = storageRef.child(
        file.split('/')[file.split('/').length - 1]
      );
      try {
        await fileStorageRef.delete();
      } catch (error) {
        console.log('El archivo no se encuentra almacenado');
      }
    }

    // Definir estructura del requerimiento
    const oldCommonRequirement = {
      file,
      message,
      title,
    };

    // Actualizar información de los requerimientos del ejecutivo
    await executiveRef.update({
      commonRequirements: this.fieldValue.arrayRemove(oldCommonRequirement),
    });

    // Devolver nuevo arrreglo
    return oldCommonRequirement;
  };
}

export default applicationLogic;
