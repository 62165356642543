// Importar librerías
import React, { useState, useContext } from 'react';
import { Modal, Button, Form, Input, message } from 'antd';

// Importar context de firebase
import { FirebaseContext } from './../../context';

// Importar hooks
import useWindowSize from '../../hooks/useWindowSize';

const PasswordChangeModal = ({ openModal, setOpenModal }) => {
  // Definir state
  const [loading, setLoading] = useState(false);

  // Obtener información del context
  const firebase = useContext(FirebaseContext);

  // Definir nueva instancia de useForm
  const [form] = Form.useForm();

  // Obtener tamaño de la ventana
  const windowWidth = useWindowSize();

  /**
   * Cierra una ventana modal.
   */
  const handleCancel = () => {
    setOpenModal(false);
  };

  /**
   *
   * @param {*} values
   * Actualiza la información de la contraseña de un usuario.
   */
  const onFinish = (values) => {
    setLoading(true);
    const { passwordOne, passwordTwo } = values;
    if (passwordOne !== passwordTwo) {
      message.error('Las contraseñas no coinciden');
      setLoading(false);
    } else {
      firebase.authLogic
        .doPasswordUpdate(passwordOne)
        .then(() => {
          message.success('Contraseña actualizada con éxito');
          form.resetFields();
          setLoading(false);
          setOpenModal(false);
        })
        .catch((error) => {
          setLoading(false);
          message.error(error.message);
        });
    }
  };

  /**
   *
   * @param {*} errorInfo
   * Muestra posibles errores al intentar cambiar la contraseña.
   */
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
    message.error('Error');
  };

  return (
    <Modal
      visible={openModal}
      onOk={onFinish}
      confirmLoading={loading}
      onCancel={handleCancel}
      width={windowWidth < 992 ? 350 : 520}
      footer={
        <div id="change-modal-footer">
          <Button
            loading={loading}
            form="modal-new-change-form"
            key="submit"
            htmlType="submit"
            type={'primary'}
          >
            Cambiar contraseña
          </Button>
        </div>
      }
    >
      <div id="change-new-modal-body">
        <h3>Cambio de Contraseña</h3>

        <Form
          name="change_form"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          id="modal-new-change-form"
          form={form}
          layout="vertical"
          hideRequiredMark={true}
        >
          <Form.Item
            name="passwordOne"
            rules={[
              {
                required: true,
                message: 'Por favor ingresa tu Password!',
              },
            ]}
          >
            <Input type="password" placeholder="Nueva Contraseña" />
          </Form.Item>
          <Form.Item
            name="passwordTwo"
            rules={[
              {
                required: true,
                message: 'Por favor repite tu Password!',
              },
            ]}
          >
            <Input type="password" placeholder="Confirma la Contraseña" />
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

export default PasswordChangeModal;
