import * as ROLES from './roles';

const authenticatedUserCondition = (authUser) => !!authUser;
const bankAdminUserCondition = (authUser) =>
  authUser && !!authUser.roles[ROLES.BANK_ADMIN];
const bankExecutiveUserCondition = (authUser) =>
  authUser && !!authUser.roles[ROLES.BANK_EXECUTIVE];
const bankGeneralUserCondition = (authUser) =>
  authUser &&
  (!!authUser.roles[ROLES.BANK_ADMIN] || !!authUser.roles[ROLES.BANK_EXECUTIVE]);

export {
  authenticatedUserCondition,
  bankAdminUserCondition,
  bankExecutiveUserCondition,
  bankGeneralUserCondition,
};
