// Importar librerías
import React from 'react';
import { Link } from 'react-router-dom';
import { useCollection } from 'react-firebase-hooks/firestore';
import { Divider, Space, Table, Tag, Tooltip } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

// Importar rutas
import * as ROUTES from './../../constants/routes';

// Importar utilidades
import { withFirebase } from '../../utils';

// Importar hooks
import useWindowSize from '../../hooks/useWindowSize';

// Asigna puntos a un valor numérico (TODO: Redactar mejor)
const numberWithPoints = (x) => {
  return x.toLocaleString();
};

// Definir información del ícono de nuevos mensajes
const iconMessage = 'Hay nuevos mensajes del usuario asociados a esta solicitud';

const TakenApplicationsPage = ({ authUser, firebase, ...rest }) => {
  // Obtener información de la BD
  const [takenApplications, loadingApplications, errorLoading] = useCollection(
    firebase.db
      .collection('applications')
      .where('winner.executiveId', '==', authUser.uid)
      .where('winner.status', '==', 'offer'),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  // Obtener tamaño de la ventana
  const windowWidth = useWindowSize();

  // Definir columnas de la tabla
  const columns = [
    {
      title: 'SOLICITUD',
      key: 'id',
      render: (doc) => {
        // Obtener último elemento del chat
        const chatLastItem =
          doc.get('chat').length >= 2
            ? doc.get('chat')[doc.get('chat').length - 1]
            : null;

        // Definir booleano para mostrar ícono
        const showWarningIcon =
          chatLastItem && chatLastItem.senderId !== authUser.uid ? true : false;

        // Renderizar elemento
        return (
          <Space>
            {showWarningIcon && (
              <Tooltip placement="top" title={iconMessage} color="#e81687">
                <ExclamationCircleOutlined className="info-icon-style" />
              </Tooltip>
            )}
            <Link id="link" to={`${ROUTES.TAKEN_APPLICATIONS_PAGE}/${doc.id}`}>
              Ver
            </Link>
          </Space>
        );
      },
      show: true,
    },
    {
      title: 'USUARIO',
      key: 'name',
      render: (doc) =>
        doc.data().user
          ? `${doc.data().user.firstName} ${doc.data().user.lastName}`
          : 'No name',
      sorter: (a, b) => a.get('user').lastName.localeCompare(b.get('user').lastName),
      show: windowWidth < 768 ? false : true,
    },
    {
      title: 'MONTO DEL CRÉDITO',
      key: 'credito',
      render: (doc) => {
        const type = doc.get('type');
        switch (type) {
          case 'portabilidad-hipotecario':
          case 'hipotecario':
            return `UF ${numberWithPoints(Math.round(doc.get('credito')))}`;
          case 'portabilidad-consumo':
          case 'consumo':
            return `CLP ${numberWithPoints(Math.round(doc.get('credito')))}`;
          case 'automotriz':
            return `$ ${numberWithPoints(Math.round(doc.get('credito')))}`;
          default:
            return <p>-</p>;
        }
      },
      sorter: (a, b) => a.get('credito') - b.get('credito'),
      show: true,
    },
    {
      title: 'TIPO',
      key: 'type',
      render: (doc) => {
        const type = doc.get('type');
        switch (type) {
          case 'portabilidad-hipotecario':
            return <p>Portabilidad Hipotecario</p>;
          case 'hipotecario':
            return <p>Hipotecario</p>;
          case 'portabilidad-consumo':
            return <p>Portabilidad Consumo</p>;
          case 'consumo':
            return <p>Consumo</p>;
          case 'automotriz':
            return <p>Automotriz</p>;
          default:
            return <p>Default</p>;
        }
      },
      sorter: (a, b) => (a.get('type') || '').localeCompare(b.get('type') || ''),
      filters: [
        {
          text: 'Portabilidad Hipotecario',
          value: 'portabilidad-hipotecario',
        },

        {
          text: 'Hipotecario',
          value: 'hipotecario',
        },
        {
          text: 'Portabilidad Consumo',
          value: 'portabilidad-consumo',
        },
        {
          text: 'Consumo',
          value: 'consumo',
        },
        {
          text: 'Automotriz',
          value: 'automotriz',
        },
        {
          text: 'Default',
          value: 'default',
        },
      ],
      onFilter: (value, doc) => (doc.get('type') || 'default') === value,
      show: true,
    },
    {
      title: 'ESTADO',
      key: 'status',
      render: (doc) => {
        const status = doc.get('status');
        if (!status || status === 'accepted')
          return (
            <Tag color={'#E3D7FE'} className={'purple'}>
              En Trámite
            </Tag>
          );
        if (status === 'rejected' || status === 'bankRejected')
          return (
            <Tag color={'#FBD3DF'} className={'red'}>
              Rechazado
            </Tag>
          );
        if (status === 'userRejected')
          return (
            <Tag color={'#FBD3DF'} className={'red'}>
              Rechazado por Usuario
            </Tag>
          );

        return (
          <Tag color={'#E3D7FE'} className={'purple'}>
            En Trámite
          </Tag>
        );
      },
      show: true,
    },
    {
      title: 'FECHA CREACIÓN',
      key: 'date',
      render: (doc) => doc.data().createdAt.toDate().toISOString().substring(0, 10),
      sorter: (a, b) => {
        return a.get('createdAt').toDate() - b.get('createdAt').toDate();
      },
      defaultSortOrder: 'descend',
      show: windowWidth < 768 ? false : true,
    },
  ];

  // Renderizar componente
  return (
    <div className="applications-list">
      <h1 id="assign-title">
        Solicitudes adjudicadas a {authUser.firstName} {authUser.lastName}
      </h1>
      <Divider />
      {errorLoading && <strong>errorLoading: {JSON.stringify(errorLoading)}</strong>}
      {!errorLoading && (
        <div className="applications-table">
          <Table
            columns={columns.filter((item) => item.show)}
            dataSource={loadingApplications ? [] : takenApplications.docs}
            rowKey="id"
            loading={loadingApplications}
            pagination={false}
            size={windowWidth < 768 ? 'small' : 'large'}
          />
        </div>
      )}
    </div>
  );
};

export default withFirebase(TakenApplicationsPage);
