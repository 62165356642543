// Importar librerías
import React, { useContext } from 'react';
import { Switch, Route } from 'react-router-dom';

// Importar utilidades y constantes
import { withAuthorization } from '../../utils';
import { bankAdminUserCondition } from '../../constants/conditions';

// Importar context de user
import { AuthUserContext } from '../../context';

// Importar otros componentes
import AssingList from './AssignList';

// Importar rutas
import * as ROUTES from '../../constants/routes';

const AssignApplicationsPage = () => {
  // Obtener información del context de user
  const authUser = useContext(AuthUserContext);

  // Renderizar componente
  return (
    <>
      <Switch>
        {/* Carga por defecto la lista */}
        <Route
          exact
          path={ROUTES.ASSIGN_LIST_PAGE}
          render={(props) => <AssingList {...props} authUser={authUser} />}
        />
      </Switch>
    </>
  );
};

export default withAuthorization(bankAdminUserCondition)(AssignApplicationsPage);
