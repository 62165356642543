class bankLogic {
  constructor(db, fieldValue) {
    this.db = db;
    this.fieldValue = fieldValue;
  }

  loadFromName = (bankName) =>
    this.db.collection('banks').where('bankName', '==', bankName);
}

export default bankLogic;
