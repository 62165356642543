// Importar librerías
import React from 'react';
import { Modal, Button } from 'antd';

// Importar hooks
import useWindowSize from '../../hooks/useWindowSize';

const ShowExecutiveModal = ({
  openShowExecutiveModal,
  setOpenShowExecutiveModal,
  openModalRecord,
}) => {
  // Obtener tamaño de la ventana
  const windowWidth = useWindowSize();

  /**
   * Muestra u oculta una ventana modal.
   */
  const handleSubmit = () => {
    setOpenShowExecutiveModal(false, {});
  };

  /**
   * Permite cerrar una ventana modal.
   */
  const handleCancel = () => {
    setOpenShowExecutiveModal(false, {});
  };

  // Aplicar destructuración para obtener la información del ejecutivo
  const { firstName, lastName, email, bankName, bankOfficeName } = openModalRecord;

  // Renderizar componente
  return (
    <Modal
      visible={openShowExecutiveModal}
      onOk={handleSubmit}
      onCancel={handleCancel}
      width={windowWidth < 992 ? 350 : 520}
      footer={
        <div id="executive-modal-footer">
          <Button onClick={handleSubmit} type={'primary'}>
            Aceptar
          </Button>
        </div>
      }
    >
      <div id={'executive-show-modal-body'}>
        <h3>
          Ejecutivos del {bankName} {bankOfficeName ? `(${bankOfficeName})` : ''}
        </h3>
        <span id={'names'}>
          {firstName} {lastName}
        </span>
        <span id={'email'}>{email}</span>
      </div>
    </Modal>
  );
};

export default ShowExecutiveModal;
