import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/storage';

import config from './../../config/firebase';
import authenticationLogic from './authentication';
import userLogic from './user';
import executiveLogic from './executive';
import bankLogic from './bank';
import applicationLogic from './application';

/**Lo que esta en la clase Firebase, puede ser accedido en cualquier lugar. Para usarlos hay que hacer 2 cosas
1. Poner a lo mas arriba del arbol <FirebaseContext.Provider value=new Firebase()> (Ya esta)
2. Cuando queramos acceder a algun elemento de la clase firebase usar 
<FirebaseContext.Consumer>
  {firebase => {
    return <div>I've access to Firebase and render something.</div>;
  }}
</FirebaseContext.Consumer> 
*/
class Firebase {
  constructor() {
    app.initializeApp(config);

    /* Helper */

    this.fieldValue = app.firestore.FieldValue;
    this.emailAuthProvider = app.auth.EmailAuthProvider;

    /* Firebase APIs */
    this.auth = app.auth();
    this.db = app.firestore();
    this.functions = app.functions();
    this.storage = app.storage();

    // Funciones de authenticacion
    this.authLogic = new authenticationLogic(app, this.auth);

    // Funciones de colecciones de usuario
    this.userLogic = new userLogic(this.db);

    // Funciones de ejecutivos
    this.executiveLogic = new executiveLogic(
      this.db,
      this.fieldValue,
      this.functions
    );

    // Funciones de bancos
    this.bankLogic = new bankLogic(this.db, this.fieldValue);

    // Funciones de aplicaciones
    this.applicationLogic = new applicationLogic(
      this.db,
      this.fieldValue,
      this.storage,
      this.executiveLogic
    );
  }

  // *** Merge Auth and DB User API *** //

  getDownloadURL = (filePath) => {
    const storageRef = this.storage.ref();
    const fileRef = storageRef.child(filePath);

    return fileRef.getDownloadURL().catch((e) => null);
  };
  onAuthUserListener = (next, fallback) =>
    this.auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        this.userLogic
          .user(authUser.uid)
          .get()
          .then((snapshot) => {
            const dbUser = snapshot.data();

            // default empty roles
            if (!dbUser.roles) {
              dbUser.roles = {};
            }

            // merge auth and db user
            authUser = {
              uid: authUser.uid,
              email: authUser.email,
              emailVerified: authUser.emailVerified,
              providerData: authUser.providerData,
              ...dbUser,
            };

            next(authUser);
          });
      } else {
        fallback();
      }
    });
}

export default Firebase;
