// Importar librerías
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Spin, Tabs, Tag } from 'antd';
import { useDocument } from 'react-firebase-hooks/firestore';

// Importar rutas
import * as ROUTES from '../../constants/routes';

// Importar utilidades y constantes
import { withFirebase, withAuthUser } from '../../utils';
import { ReactComponent as BackIcon } from './assets/back.svg';
import { appOptionsNames } from '../../constants/appTypes';

// Importar otros componentes
import TakenApplicationAnswerShowIndex from './Answer/TakenApplicationAnswerShowIndex';
import TakenApplicationRequirements from './TakenApplicationRequirements';
import TakenApplicationChat from './TakenApplicationChat';
import ApplicationDataIndex from './../Applications/ApplicationData';

// Importar subcomponente
const { TabPane } = Tabs;

// Definir formato de fecha
const DATE_FORMAT = 'HH:mm DD/MM/YYYY';

const showApplicationCalc = (applicationDoc, authUser) => {
  if (applicationDoc && !applicationDoc.exists) {
    return false;
  }
  // Funcion para ver si este ejecutivo gano y puede ver solicitud

  if (applicationDoc && !applicationDoc.get('winner')) return false;

  let showApplication = true;
  if (applicationDoc && !applicationDoc.get('winner').executiveId === authUser.id) {
    showApplication = false;
  }

  return showApplication;
};

const TakenApplicationItem = ({ authUser, firebase, ...props }) => {
  // Obtener id de la cotización
  const applicationId = props.match.params.id;

  // Obtener información de la BD
  const [applicationDoc, loading, error] = useDocument(
    firebase.applicationLogic.application(applicationId),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  // Definir state
  const [applicationLoaded, setApplicationLoaded] = useState(false);
  const [activeTab, setActiveTab] = useState('application');

  // Verificar si el usuario actual puede ver y editar la cotización
  const showApplication = showApplicationCalc(applicationDoc, authUser);

  // Definir effect para setear la información de la cotización
  useEffect(() => {
    if (error) {
      setApplicationLoaded(false);
    }
    if (loading) {
      setApplicationLoaded(false);
    } else {
      if (applicationDoc.exists) {
        setApplicationLoaded(true);
      } else {
        setApplicationLoaded(false);
      }
    }
  }, [applicationDoc, loading, error, authUser]);

  // Definir effect para redireccionar al usuario en caso de que no tenga permisos
  // sobre la cotización
  useEffect(() => {
    if (!showApplication) {
      props.history.push(ROUTES.HOME);
    }
  });

  // Validar status de la cotización
  let rejected = false;
  if (applicationLoaded) {
    const status = applicationDoc.get('status');
    if (status === 'rejected' || status === 'bankRejected') rejected = true;
  }

  // Obtener tipo y datos de la aplicación
  const application = applicationLoaded ? applicationDoc.data() : null;
  const applicationType = applicationLoaded ? applicationDoc.get('type') : null;

  // Renderizar componente
  return (
    <Spin spinning={loading}>
      <div>
        {error && <strong>Error: {JSON.stringify(error)}</strong>}
        {loading && <span>Document: Loading...</span>}
        {applicationLoaded && showApplication && (
          <div className="application-show">
            <div className="application-header">
              <div className="application-type">
                {appOptionsNames[applicationType]}
                <div className="back">
                  {authUser.roles.BANKEXECUTIVE === 'BANKEXECUTIVE' ? (
                    <Link to={ROUTES.APPLICATIONS_PAGE}>
                      <BackIcon /> Volver a mis solicitudes
                    </Link>
                  ) : (
                    <Link to={ROUTES.ASSIGN_LIST_PAGE}>
                      <BackIcon /> Volver a asignación
                    </Link>
                  )}
                </div>
              </div>
              <div className="main-info">
                <div className="title">
                  <div className={'top'}>
                    <span className="name">{applicationDoc.get('nombre')}</span>
                    <span className="createdAt">
                      {moment(applicationDoc.get('createdAt').toDate()).format(
                        DATE_FORMAT
                      )}{' '}
                      hrs
                    </span>
                  </div>
                  <div className={'bottom'}>
                    <span className="userName">
                      {`${applicationDoc.get('user').firstName} ${
                        applicationDoc.get('user').lastName
                      }`}
                    </span>
                    <span className={'rut'}>{applicationDoc.get('rut')}</span>
                    <span className={'location'}>
                      {applicationDoc.get('region')}
                      {' - '}
                      {applicationDoc.get('comuna')}
                    </span>
                  </div>
                </div>
                <div className="extra">
                  <div className="time-limit">
                    <span className="bold">Plazo de respuesta {'cerrado'}</span>
                  </div>
                </div>
              </div>
              <div className="labels">
                {!rejected && <Tag color={'#E3D7FE'}>En Trámite</Tag>}
                {rejected && <Tag color={'#FBD3DF'}>Oferta Rechazada</Tag>}
                <Tag className="bold" color={'#05035811'}>
                  {applicationDoc.id}
                </Tag>
              </div>
            </div>
            <Tabs activeKey={activeTab} onTabClick={setActiveTab}>
              <TabPane tab={'Solicitud'} key="application">
                <ApplicationDataIndex
                  application={application}
                  applicationType={applicationType}
                />
              </TabPane>
              <TabPane tab={'Oferta'} key="answer">
                <TakenApplicationAnswerShowIndex
                  application={applicationDoc}
                  applicationType={applicationType}
                  rejected={rejected}
                />
              </TabPane>
              <TabPane tab={'Chat Cliente'} key="chat">
                <TakenApplicationChat
                  application={applicationDoc}
                  authUser={authUser}
                  firebase={firebase}
                  rejected={rejected}
                />
              </TabPane>
              <TabPane tab={'Requerimientos'} key="requirements">
                <TakenApplicationRequirements
                  application={applicationDoc}
                  authUser={authUser}
                  firebase={firebase}
                  rejected={rejected}
                />
              </TabPane>
            </Tabs>
          </div>
        )}
      </div>
    </Spin>
  );
};

export default withAuthUser(withFirebase(TakenApplicationItem));
