// Importar librerías
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Table, Modal, message, Divider, Tooltip, Button, Tag } from 'antd';
import { useCollectionDataOnce } from 'react-firebase-hooks/firestore';

// Importar utilidades
import { withFirebase } from '../../utils';

// Importar otros componentes
import AssignModal from './AssignModal';

// Importar hooks
import useWindowSize from '../../hooks/useWindowSize';

// Coloca puntos a un valor numérico
const numberWithPoints = (x) => {
  return x.toLocaleString();
};

/**
 *
 * @param {*} assignApplication
 * @param {*} setAssignApplication
 * @param {*} selectedExecutive
 * @param {*} setSelectedExecutive
 * @param {*} setconfirmLoading
 * @param {*} setShowUnassign
 * @param {*} authUser
 * @param {*} firebase
 * Quita la asignación de una cotización a un ejecutivo.
 */
const unassignExecutive = async (
  assignApplication,
  setAssignApplication,
  selectedExecutive,
  setSelectedExecutive,
  setconfirmLoading,
  setShowUnassign,
  authUser,
  firebase
) => {
  // Habilitar spinner de carga
  setconfirmLoading(true);

  // Intentar actualizar en la BD
  try {
    // Definir valores a ser enviados
    const values = {
      bankName: authUser.bankName,
      bankOfficeName: authUser.bankOfficeName,
      assignApplication,
      selectedExecutive,
    };

    // Enviar a BD
    await firebase.applicationLogic.unassignExecutive(values);

    // Resetear valores
    setAssignApplication(undefined);
    setSelectedExecutive(undefined);
    setShowUnassign(false);
  } catch (error) {
    message.error(error.message);
  }

  // Deshabilitar spinner
  setconfirmLoading(false);
};

/**
 *
 * @param {*} assignApplication
 * @param {*} setAssignApplication
 * @param {*} selectedExecutive
 * @param {*} setSelectedExecutive
 * @param {*} setconfirmLoading
 * @param {*} setShowAssign
 * @param {*} authUser
 * @param {*} firebase
 * Asigna un ejecutivo a una cotización disponible.
 */
const assignExecutive = async (
  assignApplication,
  setAssignApplication,
  selectedExecutive,
  setSelectedExecutive,
  setconfirmLoading,
  setShowAssign,
  authUser,
  firebase
) => {
  // Habilitar spinner de carga
  setconfirmLoading(true);

  // Validar que un ejecutivo haya sido seleccionado
  if (!selectedExecutive) {
    setconfirmLoading(false);
    return;
  }

  try {
    // Definir valores que serán enviados
    const values = {
      bankName: authUser.bankName,
      bankOfficeName: authUser.bankOfficeName,
      assignApplication,
      selectedExecutive,
    };

    // Intentar actualizar
    await firebase.applicationLogic.assignExecutive(values);

    // Resetear valores
    setAssignApplication(undefined);
    setShowAssign(false);
  } catch (error) {
    console.log(error);
    message.error(error.message);
  }

  // Deshabilitar spinner de carga y reinicializar al ejecutivo seleccionado
  setSelectedExecutive(undefined);
  setconfirmLoading(false);
};

const AssignList = ({ authUser, firebase, ...rest }) => {
  // Definir state
  const [loadingApplications, setLoadingA] = useState(true);
  const [allApplications, setAllApplications] = useState({ docs: [] });
  const errorLoading = null;

  // Definir effect para obtener información de todas las cotizaciones
  useEffect(() => {
    // No cambiar a react hook porque cargar por fecha no funciona
    const unsubscribe = firebase.applicationLogic
      .allApplications()
      .orderBy('createdAt', 'desc')
      .onSnapshot((snapshot) => {
        if (snapshot.size) {
          // Filtrar solicitudes en función de los tipos de solicitudes que maneja
          // el administrador actual
          const appTypes = snapshot.docs.filter((item) =>
            authUser.bankOfficeAppTypes.includes(item.get('type'))
          );
          setAllApplications({ docs: appTypes });
        }
        setLoadingA(false);
      });
    return () => {
      unsubscribe();
    };
  }, [firebase.applicationLogic, authUser]);

  // Obtener información de los ejecutivos asociados a un banco en particular
  const [executivesFull] = useCollectionDataOnce(
    firebase.executiveLogic.executives(authUser.bankId, authUser.bankOfficeId),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  const [showAssign, setShowAssign] = useState(false);
  const [showUnassignModal, setShowUnassign] = useState(false);

  const [assignApplication, setAssignApplication] = useState(undefined);
  const [selectedExecutive, setSelectedExecutive] = useState(undefined);
  const [confirmLoading, setconfirmLoading] = useState(false);

  // Obtener tamaño de la ventana
  const windowWidth = useWindowSize();

  // Definir columnas de la tabla
  const columns = [
    {
      title: 'SOLICITUD',
      key: 'id',
      render: (doc) => (
        <Link id="link" to={`/applications/${doc.id}`}>
          Ver
        </Link>
      ),
      show: true,
    },
    {
      title: 'USUARIO',
      key: 'name',
      render: (doc) =>
        doc.data().user
          ? `${doc.data().user.firstName} ${doc.data().user.lastName}`
          : 'No name',
      sorter: (a, b) => a.get('user').lastName.localeCompare(b.get('user').lastName),
      show: windowWidth < 992 ? false : true,
    },
    {
      title: 'MONTO DEL CRÉDITO',
      key: 'credito',
      render: (doc) => {
        const type = doc.get('type');
        switch (type) {
          case 'portabilidad-hipotecario':
          case 'hipotecario':
            return `UF ${numberWithPoints(Math.round(doc.get('credito')))}`;
          case 'portabilidad-consumo':
          case 'consumo':
            return `CLP ${numberWithPoints(Math.round(doc.get('credito')))}`;
          case 'automotriz':
            return `$ ${numberWithPoints(Math.round(doc.get('credito')))}`;
          default:
            return <p>-</p>;
        }
      },
      sorter: (a, b) => a.get('credito') - b.get('credito'),
      show: true,
    },
    {
      title: 'EJECUTIVO ASIGNADO',
      key: 'executive',
      render: (doc) => {
        const executives = doc.get('executives') || [];
        const bankExecutive = executives.find(
          (d) =>
            d.bankName === authUser.bankName &&
            d.bankOfficeName &&
            d.bankOfficeName === authUser.bankOfficeName
        );

        if (!bankExecutive) {
          return (
            <span id={'link-disabled'}>
              <p>no asignado</p>
            </span>
          );
        }
        return <span>{bankExecutive.email}</span>;
      },
      sorter: (a, b) => {
        const executivesA = a.get('executives') || [];
        let bankExecutiveA;
        try {
          bankExecutiveA = executivesA.find(
            (d) =>
              d.bankName === authUser.bankName &&
              d.bankOfficeName &&
              d.bankOfficeName === authUser.bankOfficeName
          ).email;
        } catch {
          bankExecutiveA = 'no asignado';
        }
        const executivesB = b.get('executives') || [];
        let bankExecutiveB;
        try {
          bankExecutiveB = executivesB.find(
            (d) =>
              d.bankName === authUser.bankName &&
              d.bankOfficeName &&
              d.bankOfficeName === authUser.bankOfficeName
          ).email;
        } catch {
          bankExecutiveB = 'no asignado';
        }

        return bankExecutiveA.localeCompare(bankExecutiveB);
      },
      show: windowWidth < 992 ? false : true,
    },
    {
      title: 'TIPO',
      key: 'type',
      render: (doc) => {
        const type = doc.get('type');
        switch (type) {
          case 'portabilidad-hipotecario':
            return (
              <Tag color={'green'} className="mini-tag">
                Portabilidad Hipotecario
              </Tag>
            );
          case 'hipotecario':
            return (
              <Tag color={'green'} className="mini-tag">
                Hipotecario
              </Tag>
            );
          case 'portabilidad-consumo':
            return (
              <Tag color={'blue'} className="mini-tag">
                Portabilidad Consumo
              </Tag>
            );
          case 'consumo':
            return (
              <Tag color={'blue'} className="mini-tag">
                Consumo
              </Tag>
            );
          case 'automotriz':
            return (
              <Tag color={'orange'} className="mini-tag">
                Automotriz
              </Tag>
            );
          default:
            return (
              <Tag color={'red'} className="mini-tag">
                Default
              </Tag>
            );
        }
      },
      sorter: (a, b) => (a.get('type') || '').localeCompare(b.get('type') || ''),
      filters: [
        {
          text: 'Portabilidad Hipotecario',
          value: 'portabilidad-hipotecario',
        },

        {
          text: 'Hipotecario',
          value: 'hipotecario',
        },
        {
          text: 'Portabilidad Consumo',
          value: 'portabilidad-consumo',
        },
        {
          text: 'Consumo',
          value: 'consumo',
        },
        {
          text: 'Automotriz',
          value: 'automotriz',
        },
        {
          text: 'Default',
          value: 'default',
        },
      ],
      onFilter: (value, doc) => (doc.get('type') || 'default') === value,
      show: true,
    },
    {
      title: 'NOMBRE EJECUTIVO',
      key: 'executiveName',
      render: (doc) => {
        if (!executivesFull) return;
        const executives = doc.get('executives') || [];
        const bankExecutive = executives.find(
          (d) =>
            d.bankName === authUser.bankName &&
            d.bankOfficeName &&
            d.bankOfficeName === authUser.bankOfficeName
        );
        if (!bankExecutive) return <span>-</span>;

        const { email, bankOfficeName } = bankExecutive;
        const executiveFull = executivesFull.find(
          (d) => d.email === email && d.bankOfficeName === bankOfficeName
        );

        if (!executiveFull) return <span>-</span>;

        return (
          <span>
            {executiveFull.firstName} {executiveFull.lastName}
          </span>
        );
      },
      sorter: (a, b) => {
        const executivesA = a.get('executives') || [];
        let bankExecutiveA;
        try {
          bankExecutiveA = executivesA.find(
            (d) =>
              d.bankName === authUser.bankName &&
              d.bankOfficeName &&
              d.bankOfficeName === authUser.bankOfficeName
          ).email;
        } catch {
          bankExecutiveA = 'Asignar';
        }
        const executivesB = b.get('executives') || [];
        let bankExecutiveB;
        try {
          bankExecutiveB = executivesB.find(
            (d) =>
              d.bankName === authUser.bankName &&
              d.bankOfficeName &&
              d.bankOfficeName === authUser.bankOfficeName
          ).email;
        } catch {
          bankExecutiveB = 'Asignar';
        }

        return bankExecutiveA.localeCompare(bankExecutiveB);
      },
      show: true,
    },
    {
      title: 'FECHA CREACIÓN',
      key: 'date',
      render: (doc) => doc.data().createdAt.toDate().toISOString().substring(0, 10),
      sorter: (a, b) => {
        return a.get('createdAt').toDate() - b.get('createdAt').toDate();
      },
      defaultSortOrder: 'descend',
      show: windowWidth < 992 ? false : true,
    },
    {
      title: ' ACCIONES',
      key: 'actions',
      render: (doc) => {
        // Obtener información de los ejecutivos de bancos
        const executives = doc.get('executives') || [];
        const bankExecutive = executives.find(
          (d) =>
            d.bankName === authUser.bankName &&
            d.bankOfficeName &&
            d.bankOfficeName === authUser.bankOfficeName
        );

        // Renderizar asignación o desasignación según sea el caso
        if (!bankExecutive)
          return (
            <span
              id="link"
              onClick={() => {
                setShowAssign(true);
                setAssignApplication(doc);
              }}
            >
              <Button
                type="primary"
                size={windowWidth < 768 ? 'small' : 'middle'}
                className="min-button"
              >
                Asignar
              </Button>
            </span>
          );

        // Renderizar componente para desasignación de ejecutivo
        return (
          <Tooltip title="Eliminar ejecutivo asignado">
            <span
              id={'link-ok'}
              type={'link'}
              onClick={() => {
                setShowUnassign(true);
                setAssignApplication(doc);
                setSelectedExecutive({
                  executiveEmail: bankExecutive.email,
                  executiveId: bankExecutive.id,
                });
              }}
            >
              <Button
                danger="true"
                size={windowWidth < 768 ? 'small' : 'middle'}
                className="min-button"
              >
                Desasignar
              </Button>
            </span>
          </Tooltip>
        );
      },
      show: true,
    },
  ];

  // Renderizar componente
  return (
    <div className="assign-list">
      <h1 id="assign-title">
        Solicitudes activas para {authUser.bankName} ({authUser.bankOfficeName})
      </h1>
      <Divider />
      {errorLoading && <strong>errorLoading: {JSON.stringify(errorLoading)}</strong>}
      {/* {loadingApplications && <span>Collection: loadingApplications...</span>} */}
      {!errorLoading && (
        <div>
          {showUnassignModal && (
            <Modal
              visible={showUnassignModal}
              confirmLoading={confirmLoading}
              width={windowWidth < 992 ? 350 : 520}
              onCancel={() => {
                setShowUnassign(false);
                setAssignApplication(undefined);
                setSelectedExecutive(undefined);
              }}
              footer={
                <div id="assign-modal-footer">
                  <Button
                    loading={confirmLoading}
                    type={'primary'}
                    onClick={() =>
                      unassignExecutive(
                        assignApplication,
                        setAssignApplication,
                        selectedExecutive,
                        setSelectedExecutive,
                        setconfirmLoading,
                        setShowUnassign,
                        authUser,
                        firebase
                      )
                    }
                  >
                    Aceptar
                  </Button>
                </div>
              }
            >
              <div id="unassign-modal-body">
                <h3>¿Seguro que quiere desasignar a este ejecutivo?</h3>
                <p>
                  Si ya existía una respuesta a la solicitud y no asigna a nadie más,
                  se va a seguir dando el email de este ejecutivo
                </p>
              </div>
            </Modal>
          )}
          {showAssign && (
            <AssignModal
              setShowAssign={setShowAssign}
              setAssignApplication={setAssignApplication}
              setSelectedExecutive={setSelectedExecutive}
              confirmLoading={confirmLoading}
              assignExecutive={assignExecutive}
              assignApplication={assignApplication}
              selectedExecutive={selectedExecutive}
              setconfirmLoading={setconfirmLoading}
              authUser={authUser}
              firebase={firebase}
              showAssign={showAssign}
              executivesFull={executivesFull.filter(
                (item) =>
                  item.bankOfficeName &&
                  item.bankOfficeName === authUser.bankOfficeName &&
                  item.bankOfficeBoss &&
                  item.bankOfficeBoss === authUser.email
              )}
              windowWidth={windowWidth}
            />
          )}
          <div id="assign-main-table">
            <Table
              columns={columns.filter((item) => item.show)}
              dataSource={loadingApplications ? [] : allApplications.docs}
              rowKey="id"
              loading={loadingApplications}
              pagination={false}
              size={windowWidth < 992 ? 'small' : 'large'}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default withFirebase(AssignList);
