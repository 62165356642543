// Importar librerías
import React from 'react';
import moment from 'moment';
import { Descriptions, Checkbox } from 'antd';

// Importar otros componentes
import ApplicationFilesIndex from './ApplicationFiles';
import { ReactComponent as IsInIcon } from './assets/isIn.svg';
import { ReactComponent as NotInIcon } from './assets/notIn.svg';

/**
 *
 * @param {*} string
 * Recibe un string y convierte la primera letra en mayúscula
 */
const capitalizeFirstLetter = (string) => {
  if (!string) return '';
  return string.charAt(0).toUpperCase() + string.slice(1);
};

// TODO: Validar qué hace esta función
const numberWithPoints = (x) => {
  return x.toLocaleString();
};

// Definir formato de fecha
const DATE_FORMAT = 'DD/MM/YYYY';

// Definir información de de los checkbox
const seguros = [
  { name: 'desgravamen', label: 'Desgravamen' },
  { name: 'cesantia', label: 'Cesantia' },
  { name: 'vida', label: 'Vida + Hospitalización' },
];

// Definir información de los seguros hipotecarios para el checkbox
export const insuranceOptions = [
  { value: 'desgravamen', label: 'Desgravamen', disabled: true },
  { value: 'incendios', label: 'Incendios', disabled: true },
  { value: 'sismos', label: 'Sismos', disabled: false },
];

// Definir información de la licencia
export const licenceOption = [
  {
    value: 'licence',
    label:
      'Autorizo la Solicitud del Certificado de Liquidación a la Institución Financiera Actual',
    disabled: true,
  },
];

const ApplicationHipotecarioData = ({ application }) => {
  // Renderizar componente
  return (
    <div id="app-data">
      <div className="main-info">
        <div>
          <span className="title">Tipo de Propiedad</span>
          <span>{capitalizeFirstLetter(application.propiedad)}</span>
        </div>
        <div>
          <span className="title">Condición de Propiedad</span>
          <span>{capitalizeFirstLetter(application.propertyCondition)}</span>
        </div>
        <div>
          <span className="title">Dirección</span>
          <span>{application.direccion}</span>
        </div>
        {application.type === 'hipotecario' && (
          <div>
            <span className="title">Valor de Propiedad</span>
            <span>UF {numberWithPoints(application.valor)}</span>
          </div>
        )}
      </div>
      <div className="main-info additional-property">
        <div>
          {application.banco ? (
            <>
              <span className="title">Institución Financiera</span>
              <span>{application.otroBanco || application.banco}</span>
            </>
          ) : (
            <>
              <span className="title">Pie</span>
              <span>{numberWithPoints(application.piePorcentaje)} %</span>
            </>
          )}
        </div>
        <div>
          {application.banco ? (
            <span className="title">Saldo Crédito Vigente</span>
          ) : (
            <span className="title">Monto del Crédito</span>
          )}
          <span>UF {numberWithPoints(application.credito)}</span>
        </div>
        <div>
          <span className="title">Plazo</span>
          <span>{application.plazo} Años</span>
        </div>
        <div className="mini-content">
          <span className="title">Seguros</span>
          <div className="insurance-content">
            {insuranceOptions.map((seguro) => {
              const isIn = application.insurance.includes(seguro.value);
              return (
                <div className="insurance-item" key={seguro.value}>
                  {isIn ? (
                    <>
                      <IsInIcon />
                      <span>{seguro.label}</span>
                    </>
                  ) : (
                    <>
                      <NotInIcon className="no-selected-icon" />
                      <span>{seguro.label}</span>
                    </>
                  )}
                </div>
              );
            })}
          </div>
        </div>
        <div>
          <span className="title">Subsidio</span>
          <span>{application.subsidio ? `UF ${application.subsidio}` : '-'}</span>
        </div>
        <div>
          <span className="title">Aval o Codeudor</span>
          <span>{application.aval ? 'Si' : 'No'}</span>
        </div>
        <div>
          <span className="title">RUT Codeudor</span>
          <span>{application.rutCod ? application.rutCod : '-'}</span>
        </div>
        <div>
          <span className="title">Seguro Desgravamen Codeudor</span>
          <span>{application.takeInsurance ? 'Si' : 'No'}</span>
        </div>
      </div>
      <div className="secondary-info">
        <div className={'part'}>
          <h2 className={'part-title'}>INGRESOS (CLP)</h2>
          <div className={'container'}>
            <div>
              <span className="title">Estado Laboral</span>
              <span>{capitalizeFirstLetter(application.estadoLaboral)}</span>
            </div>
            <div>
              <span className="title">Fijos</span>
              <span>{numberWithPoints(application.ingresoFijo)}</span>
            </div>
            <div className="second-last">
              <span className="title">Variables</span>
              <span>{numberWithPoints(application.ingresoVariable)}</span>
            </div>
            <div className={'last'}>
              <span className="title">Ingresos Totales</span>
              <span className={'focus'}>
                {numberWithPoints(application.totalIngresos)}
              </span>
            </div>
          </div>
        </div>
        {application.type === 'hipotecario' && (
          <>
            <div className={'part'}>
              <h2 className={'part-title'}>ACTIVOS (CLP)</h2>
              <div className={'container'}>
                <div>
                  <span className="title">Bienes Raíces</span>
                  <span>{numberWithPoints(application.bienesRaices)}</span>
                </div>
                <div>
                  <span className="title">Vehículos</span>
                  <span>{numberWithPoints(application.vehiculos)}</span>
                </div>
                <div>
                  <span className="title">Inversiones</span>
                  <span>{numberWithPoints(application.inversiones)}</span>
                </div>
                <div className="second-last">
                  <span className="title">Otros</span>
                  <span>{numberWithPoints(application.otrosActivos)}</span>
                </div>
                <div className={'last'}>
                  <span className="title">Total Activos</span>
                  <span className={'focus'}>
                    {numberWithPoints(application.totalPatrimonio)}
                  </span>
                </div>
              </div>
            </div>
            <div className={'part'}>
              <h2 className={'part-title'}>GASTOS MENSUALES (CLP)</h2>
              <div className={'container'}>
                <div>
                  <span className="title">Hipotecario</span>
                  <span>{numberWithPoints(application.hipotecario)}</span>
                </div>
                <div>
                  <span className="title min-text">
                    Tarjetas y Líneas de Crédito
                  </span>
                  <span>{numberWithPoints(application.tarjetasLineasCredito)}</span>
                </div>
                <div>
                  <span className="title min-text">Créditos de Consumo</span>
                  <span>{numberWithPoints(application.creditosConsumo)}</span>
                </div>
                <div className="second-last">
                  <span className="title">Otros</span>
                  <span>{numberWithPoints(application.otrosGastos)}</span>
                </div>
                <div className={'last'}>
                  <span className="title">Total Deudas</span>
                  <span className={'focus'}>
                    {numberWithPoints(application.totalDeudas)}
                  </span>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      {application.type === 'hipotecario' ? (
        <ApplicationFilesIndex
          application={application}
          applicationType={application.type}
        />
      ) : (
        <Checkbox.Group options={licenceOption} defaultValue={['licence']} />
      )}
    </div>
  );
};

const ApplicationConsumoData = ({ application }) => {
  // Renderizar componente
  return (
    <div id="app-data">
      <div className="main-info">
        <div>
          <span
            className={
              application.ingresoFijo || application.ingresoVariable
                ? 'title small-margin'
                : 'title'
            }
          >
            RUT
          </span>
          <span>{application.rut}</span>
        </div>
        <div>
          <span
            className={
              application.ingresoFijo || application.ingresoVariable
                ? 'title small-margin'
                : 'title'
            }
          >
            Región
          </span>
          <span>{application.region}</span>
        </div>
        <div>
          <span
            className={
              application.ingresoFijo || application.ingresoVariable
                ? 'title small-margin'
                : 'title'
            }
          >
            Comuna
          </span>
          <span>{application.comuna}</span>
        </div>
        <div>
          <span
            className={
              application.ingresoFijo || application.ingresoVariable
                ? 'title small-margin'
                : 'title'
            }
          >
            Estado Laboral
          </span>
          <span>{capitalizeFirstLetter(application.estadoLaboral)}</span>
        </div>
        <div>
          <span className="title">Ingreso Mensual</span>
          {application.ingresoFijo || application.ingresoVariable ? (
            <div className="container-info">
              <div className="container-data1">
                <span>Fijo</span>CLP${' '}
                {numberWithPoints(application.ingresoFijo) || 0}
                <span></span>
              </div>
              <div className="container-data2">
                <span>Variable</span>
                <span>
                  CLP$ {numberWithPoints(application.ingresoVariable) || 0}
                </span>
              </div>
            </div>
          ) : (
            <span>CLP$ {numberWithPoints(application.ingresoMensual)}</span>
          )}
        </div>
      </div>
      <div className="secondary-info">
        <div className={'holder'}>
          <Descriptions>
            {application.banco && (
              <Descriptions.Item label="Banco Actual del Crédito" span={3}>
                {application.banco}
              </Descriptions.Item>
            )}
            <Descriptions.Item
              label={`Monto ${
                application.type !== 'consumo' ? 'Vigente' : ''
              } del Crédito`}
              span={3}
            >
              ${numberWithPoints(application.credito)}
            </Descriptions.Item>
            <Descriptions.Item label="Número de Cuotas" span={3}>
              {application.plazo}
            </Descriptions.Item>
            {application.fechaPrimerPago ? (
              <Descriptions.Item label="Fecha Primer Pago" span={3}>
                {moment(application.fechaPrimerPago.toDate()).format(DATE_FORMAT)}
              </Descriptions.Item>
            ) : (
              <Descriptions.Item label="Crédito Bullet" span={3}>
                Un Solo Pago Final
              </Descriptions.Item>
            )}
            <Descriptions.Item label="Seguros" span={3}>
              <div>
                {seguros.map((seguro) => {
                  const isIn = application.seguros.includes(seguro.name);
                  if (isIn)
                    return (
                      <span key={seguro.name}>
                        <IsInIcon /> {seguro.label}
                      </span>
                    );

                  return (
                    <span key={seguro.name}>
                      <NotInIcon /> {seguro.label}
                    </span>
                  );
                })}
              </div>
            </Descriptions.Item>
          </Descriptions>
        </div>
        <div className={'holder-bottom'}></div>
      </div>
    </div>
  );
};

const ApplicationAutomotiveCreditData = ({ application }) => {
  return (
    <div id="app-data">
      <div className="main-info">
        <div className="main-info-container-1 no-border-right">
          <div>
            <span className="title small-margin">RUT</span>
            <span>{application.rut}</span>
          </div>
          <div>
            <span className="title small-margin">Región</span>
            <span>{application.region}</span>
          </div>
          <div>
            <span className="title small-margin">Comuna</span>
            <span>{application.comuna}</span>
          </div>
          <div>
            <span className="title small-margin">Estado Laboral</span>
            <span>{capitalizeFirstLetter(application.estadoLaboral)}</span>
          </div>
          <div>
            <span className="title">Ingreso Mensual</span>
            <div className="container-info">
              <div className="container-data1">
                <span>Fijo</span>CLP${' '}
                {numberWithPoints(application.ingresoFijo) || 0}
                <span></span>
              </div>
              <div className="container-data2">
                <span>Variable</span>
                <span>
                  CLP$ {numberWithPoints(application.ingresoVariable) || 0}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="main-info-container-2">
          <div>
            <span className="title">Tipo de Vehículo</span>
            <span>{application.vehicleType}</span>
          </div>
          <div>
            <span className="title">Marca</span>
            <span>{application.vehicleBrand}</span>
          </div>
          <div>
            <span className="title">Estado</span>
            <span>
              {application.vehicleCondition === 'nuevo'
                ? capitalizeFirstLetter(application.vehicleCondition)
                : capitalizeFirstLetter(application.vehicleCondition) +
                  ', ' +
                  application.vehicleYear}
            </span>
          </div>
          <div>
            <span className="title">Valor del Vehículo</span>
            <span>$ {numberWithPoints(application.vehicleValue)}</span>
          </div>
        </div>
      </div>
      <div className="secondary-info">
        <div className={'holder'}>
          <Descriptions>
            <Descriptions.Item label="Pie" span={3}>
              ${numberWithPoints(application.vehiclePie)}
            </Descriptions.Item>
            <Descriptions.Item label="Monto del Crédito" span={3}>
              ${numberWithPoints(application.credito)}
            </Descriptions.Item>
            <Descriptions.Item label="Número de Cuotas" span={3}>
              {application.plazo}
            </Descriptions.Item>
            {application.fechaPrimerPago ? (
              <Descriptions.Item label="Fecha Primer Pago" span={3}>
                {moment(application.fechaPrimerPago.toDate()).format(DATE_FORMAT)}
              </Descriptions.Item>
            ) : (
              <Descriptions.Item label="Crédito Bullet" span={3}>
                Un Solo Pago Final
              </Descriptions.Item>
            )}
          </Descriptions>
        </div>
        <div className={'holder-bottom'}></div>
      </div>
    </div>
  );
};

const ApplicationDataIndex = ({ application, applicationType }) => {
  switch (applicationType) {
    case 'portabilidad-hipotecario':
    case 'hipotecario':
      return <ApplicationHipotecarioData application={application} />;
    case 'portabilidad-consumo':
    case 'consumo':
      return <ApplicationConsumoData application={application} />;
    case 'automotriz':
      return <ApplicationAutomotiveCreditData application={application} />;
    default:
      return <div id={'app-data'}>Error</div>;
  }
};

export default ApplicationDataIndex;
