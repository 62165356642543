// Importar librerías
import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Form, Input, Button, message } from 'antd';
import { UserOutlined } from '@ant-design/icons';

// Importar rutas y decoradores
import * as ROUTES from './../../constants/routes';
import { withFirebase } from './../../utils';

// Renderizar componente de formulario de recuperación de contraseña
const PasswordForgetPage = () => {
  // Renderizar
  return (
    <div className={'password-forget'}>
      <div className="mini-phone-background">
        <h1>¿Olvidaste tu Contraseña?</h1>
        <PasswordForgetForm />
        <Link to={ROUTES.SIGN_IN}>Volver</Link>
      </div>
    </div>
  );
};

// Iniciazliar estato
const INITIAL_STATE = {
  email: '',
  error: null,
};

class PasswordForgetFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  /**
   *
   * @param {*} values
   * Confirma el email del usuario y envía un correo para restablecer la contraseña.
   */
  onSubmit = (values) => {
    const { email } = values;

    this.props.firebase.authLogic
      .doPasswordReset(email)
      .then(() => {
        message.success('Se ha enviado un email para reestablecer su contraseña');
        this.setState({ ...INITIAL_STATE });
        this.props.history.push(ROUTES.SIGN_IN);
      })
      .catch((error) => {
        this.setState({ error });
        message.error(error.message);
      });
  };

  /**
   *
   * @param {*} event
   * Escucha por los cambios hechos en el input del correo.
   */
  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  // Renderizar
  render() {
    return (
      <Form
        name="forgot_password"
        className="forgot-password-form"
        initialValues={{
          remember: true,
        }}
        layout="vertical"
        onFinish={this.onSubmit}
      >
        <Form.Item
          name="email"
          rules={[
            {
              required: true,
              message: 'Por favor coloca tu correo',
            },
          ]}
        >
          <Input
            prefix={<UserOutlined className="site-form-item-icon" />}
            placeholder="Email"
          />
        </Form.Item>
        <Button type="primary" htmlType="submit">
          Recuperar Contraseña
        </Button>
      </Form>
    );
  }
}

// Renderizar link de recuperación de contraseña
const PasswordForgetLink = () => (
  <p className="login-form-forgot">
    <Link to={ROUTES.PASSWORD_FORGET}>¿Olvidaste tu Contraseña?</Link>
  </p>
);

// Exportar componentes
export default PasswordForgetPage;

const PasswordForgetForm = withRouter(withFirebase(PasswordForgetFormBase));

export { PasswordForgetForm, PasswordForgetLink };
