export const LANDING = '/';
export const SIGN_UP = '/signup';
export const SIGN_IN = '/signin';
export const SIGN_OUT = '/signout';
export const HOME = '/home';
export const ACCOUNT = '/account';
export const PASSWORD_FORGET = '/pw-forget';

export const EXECUTIVES_PAGE = '/executives';
export const EXECUTIVE_PAGE = '/executives/:id';
export const NEW_EXECUTIVE_PAGE = '/executives/new';
export const EDIT_EXECUTIVE_PAGE = '/executives/:id/edit';

export const APPLICATIONS_PAGE = '/applications';
export const APPLICATION_PAGE = '/applications/:id';

export const TAKEN_APPLICATIONS_PAGE = '/solicitudes-adjudicadas';
export const TAKEN_APPLICATION_PAGE = '/solicitudes-adjudicadas/:id';

export const ASSIGN_LIST_PAGE = '/assign';
export const ASSIGN_PAGE = '/assign/:id';

export const REQUIREMENTS_PAGE = '/common-requirements';

// export const NEW_EXECUTIVE_PAGE = '/executives/new';
// export const EDIT_EXECUTIVE_PAGE = '/executives/:id/edit';
