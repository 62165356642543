// Importar librerías
import React, { useState } from 'react';
import { Form, InputNumber, Row, Col } from 'antd';

// Importar hooks
import useWindowSize from '../../../../hooks/useWindowSize';

// TODO: Validar qué hace esta función.
const numberWithPoints = (x) => {
  const num = x.toString().split('.');
  if (num.length === 1) {
    return num[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  }
  return num[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.') + ',' + num[1];
};

const NewMainTable = ({ disabled }) => {
  // Variables que tienen un parser distinto (mostrar % u otra cosa)
  // Definir state
  const [tasaFocus, setTasaFocus] = useState(false);
  const [cuotaMensualFocus, setCuotaMensualFocus] = useState(false);
  const [costoTotalFocus, setCostoTotalFocus] = useState(false);
  const [caeFocus, setCaeFocus] = useState(false);

  // Obtener tamaño de la ventana
  const windowWidth = useWindowSize();

  // Renderizar componente
  return (
    <Row>
      <Col span={windowWidth < 768 ? 12 : 6}>
        <span id="title-space">Tasa Mensual</span>
        <Form.Item name="tasaMensual" rules={[{ required: true, message: ' ' }]}>
          <InputNumber
            disabled={disabled}
            min={0}
            max={100}
            size={'middle'}
            step={0.5}
            type={tasaFocus ? 'number' : 'string'}
            onFocus={() => setTasaFocus(true)}
            onBlur={() => setTasaFocus(false)}
            formatter={(value) => (tasaFocus ? value : `${value}%`)}
            parser={(value) => (tasaFocus ? value : value.replace('%', ''))}
          />
        </Form.Item>
      </Col>
      <Col span={windowWidth < 768 ? 12 : 6}>
        <span id="title-space">CAE Anual</span>
        <Form.Item name="cae1" rules={[{ required: true, message: 'Agregar CAE' }]}>
          <InputNumber
            min={0}
            max={100}
            size={'middle'}
            step={0.5}
            disabled={disabled}
            type={caeFocus ? 'number' : 'string'}
            onFocus={() => setCaeFocus(true)}
            onBlur={() => setCaeFocus(false)}
            formatter={(value) => (caeFocus ? value : `${value} %`)}
            parser={(value) => (caeFocus ? value : value.replace(' %', ''))}
          />
        </Form.Item>
      </Col>
      <Col span={windowWidth < 768 ? 12 : 6}>
        <span id="title-space">Cuota Mensual ($)</span>
        <Form.Item
          name="cuotaMensual1"
          rules={[{ required: true, message: 'Agregar cuota mensual' }]}
        >
          <InputNumber
            min={0}
            max={1000000000}
            size={'middle'}
            step={1}
            disabled={disabled}
            type={cuotaMensualFocus ? 'number' : 'string'}
            onFocus={() => setCuotaMensualFocus(true)}
            onBlur={() => setCuotaMensualFocus(false)}
            formatter={(value) =>
              cuotaMensualFocus ? value : numberWithPoints(value)
            }
          />
        </Form.Item>
      </Col>
      <Col span={windowWidth < 768 ? 12 : 6}>
        <span id="title-space">Costo Total Crédito ($)</span>
        <Form.Item
          name="costoTotal1"
          rules={[{ required: true, message: 'Agregar costo total' }]}
        >
          <InputNumber
            min={0}
            max={1000000000}
            size={'middle'}
            step={1}
            disabled={disabled}
            type={costoTotalFocus ? 'number' : 'string'}
            onFocus={() => setCostoTotalFocus(true)}
            onBlur={() => setCostoTotalFocus(false)}
            formatter={(value) =>
              costoTotalFocus ? value : numberWithPoints(value)
            }
          />
        </Form.Item>
      </Col>
    </Row>
  );
};

export default NewMainTable;
