// Importar librerías
import React, { useState, useEffect } from 'react';
import { Form, InputNumber, Tooltip, Row, Col } from 'antd';

// Importar hooks
import useWindowSize from '../../../../hooks/useWindowSize';

/**
 *
 * @param {*} x
 * Coloca puntos a un valor numérico (TODO: Redactar mejor).
 */
const numberWithPoints = (x) => {
  if (x) return parseFloat(x).toLocaleString();
  return x;
};

const giveTooltip = (
  seguroDesgravamenValue,
  seguroSismoIncendioValue,
  dividendoMensualValue
) => {
  return parseFloat(
    (
      (seguroDesgravamenValue || 0) +
      (seguroSismoIncendioValue || 0) +
      (dividendoMensualValue || 0)
    ).toFixed(5)
  );
};

const MainTable = ({ application, currentBankAnswer, disabled }) => {
  // Definir state
  // Variables que tienen un parser distinto (mostrar % u otra cosa)
  const [tasaFocus, setTasaFocus] = useState(false);
  const [seguroSismoIncendioFocus, setSeguroSismoIncendioFocus] = useState(false);
  const [seguroDesgravamenFocus, setSeguroDesgravamenFocus] = useState(false);
  const [dividendoTotalUFFocus, setDividendoTotalUFFocus] = useState(false);
  const [dividendoMensualUFFocus, setDividendoMensualUFFocus] = useState(false);
  const [caeFocus, setCaeFocus] = useState(false);

  const [seguroDesgravamenValue, setseguroDesgravamenValue] = useState(0);
  const [seguroSismoIncendioValue, setseguroSismoIncendioValue] = useState(0);
  const [dividendoMensualValue, setdividendoMensualValue] = useState(0);

  // Variable para poner años
  const [plazoString, setPlazoString] = useState(null);

  // Definir effect para setear los valores iniciales con la respuesta del banco
  useEffect(() => {
    const setInitialValues = (currentBankAnswer) => {
      if (currentBankAnswer) {
        const {
          dividendoMensualUF,
          seguroSismoIncendio1,
          seguroDesgravamen1,
        } = currentBankAnswer.answer;
        setseguroDesgravamenValue(seguroDesgravamen1);
        setseguroSismoIncendioValue(seguroSismoIncendio1);
        setdividendoMensualValue(dividendoMensualUF);
      } else {
        setseguroDesgravamenValue(0);
        setseguroSismoIncendioValue(0);
        setdividendoMensualValue(0);
      }
    };
    setInitialValues(currentBankAnswer);
  }, [currentBankAnswer]);

  // Definir state para setear la información del plazo
  useEffect(() => {
    setPlazoString(`${application.get('plazo')} años`);
  }, [application, currentBankAnswer]);

  // Obtener tamaño de la ventana
  const windowWidth = useWindowSize();

  // Renderizar componente
  return (
    <Row>
      <Col span={windowWidth < 768 ? 24 : 6}>
        <span id="title-space">Plazo</span>
        <p>{plazoString}</p>
      </Col>
      <Col span={windowWidth < 768 ? 12 : 6}>
        <span id="title-space">Tasa</span>
        <Form.Item name="tasa" rules={[{ required: true, message: ' ' }]}>
          <InputNumber
            disabled={disabled}
            min={0}
            max={100}
            size={'middle'}
            step={0.5}
            type={tasaFocus ? 'number' : 'string'}
            onFocus={() => setTasaFocus(true)}
            onBlur={() => setTasaFocus(false)}
            formatter={(value) => (tasaFocus ? value : `${value}%`)}
            parser={(value) => (tasaFocus ? value : value.replace('%', ''))}
          />
        </Form.Item>
      </Col>
      <Col span={windowWidth < 768 ? 12 : 6}>
        <span id="title-space">CAE</span>
        <Form.Item
          name="cae1"
          rules={[{ required: true, message: 'Agregar CAE' }]}
          className="content-alert"
        >
          <InputNumber
            min={0}
            max={100}
            size={'middle'}
            step={0.5}
            disabled={disabled}
            type={caeFocus ? 'number' : 'string'}
            onFocus={() => setCaeFocus(true)}
            onBlur={() => setCaeFocus(false)}
            formatter={(value) => (caeFocus ? value : `${value} %`)}
            parser={(value) => (caeFocus ? value : value.replace(' %', ''))}
          />
        </Form.Item>
      </Col>
      <Col span={windowWidth < 768 ? 12 : 6}>
        <span id="title-space">Seguro desgravamen (UF)</span>
        <Form.Item
          name="seguroDesgravamen1"
          rules={[{ required: true, message: 'Agregar seguro de desgravamen' }]}
        >
          <InputNumber
            min={0}
            max={10000}
            size={'middle'}
            step={1}
            onChange={setseguroDesgravamenValue}
            disabled={disabled}
            type={seguroDesgravamenFocus ? 'number' : 'text'}
            onFocus={() => setSeguroDesgravamenFocus(true)}
            onBlur={() => setSeguroDesgravamenFocus(false)}
            formatter={(value) =>
              seguroDesgravamenFocus ? value : numberWithPoints(value)
            }
          />
        </Form.Item>
      </Col>
      <Col span={windowWidth < 768 ? 12 : 8}>
        <span id="title-space">Seguro sismo e incendio (UF)</span>
        <Form.Item
          name="seguroSismoIncendio1"
          rules={[{ required: true, message: 'Agregar seguro de sismo e incendio' }]}
        >
          <InputNumber
            min={0}
            max={10000}
            size={'middle'}
            step={1}
            disabled={disabled}
            onChange={setseguroSismoIncendioValue}
            type={seguroSismoIncendioFocus ? 'number' : 'string'}
            onFocus={() => setSeguroSismoIncendioFocus(true)}
            onBlur={() => setSeguroSismoIncendioFocus(false)}
            formatter={(value) =>
              seguroSismoIncendioFocus ? value : numberWithPoints(value)
            }
          />
        </Form.Item>
      </Col>
      <Col span={windowWidth < 768 ? 12 : 8}>
        <span id="title-space">Dividendo mensual sin seguros (UF)</span>
        <Form.Item
          name={`dividendoMensualUF`}
          rules={[{ required: true, message: 'Agregar dividendo mensual' }]}
        >
          <InputNumber
            min={0}
            max={10000}
            size={'middle'}
            step={1}
            disabled={disabled}
            onChange={setdividendoMensualValue}
            type={dividendoMensualUFFocus ? 'number' : 'string'}
            onFocus={() => setDividendoMensualUFFocus(true)}
            onBlur={() => setDividendoMensualUFFocus(false)}
            formatter={(value) =>
              dividendoMensualUFFocus ? value : numberWithPoints(value)
            }
          />
        </Form.Item>
      </Col>
      <Col span={windowWidth < 768 ? 12 : 8}>
        <span id="title-space">Dividendo total (UF)</span>
        <span>
          <Tooltip
            title={`Suma seguros y dividendo sin seguros. Deberia ser UF ${giveTooltip(
              seguroDesgravamenValue,
              seguroSismoIncendioValue,
              dividendoMensualValue
            )}`}
            placement={'bottom'}
          >
            <Form.Item
              name="dividendoTotalUF1"
              rules={[
                {
                  required: true,
                  // message: 'Agregar dividendo total',
                  validator: (_, value) => {
                    return value ===
                      giveTooltip(
                        seguroDesgravamenValue,
                        seguroSismoIncendioValue,
                        dividendoMensualValue
                      )
                      ? Promise.resolve()
                      : Promise.reject(
                          'El dividendo total debe sumar los otros valores'
                        );
                  },
                },
              ]}
            >
              <InputNumber
                min={0}
                max={10000}
                size={'middle'}
                step={1}
                disabled={disabled}
                type={dividendoTotalUFFocus ? 'number' : 'string'}
                onFocus={() => setDividendoTotalUFFocus(true)}
                onBlur={() => setDividendoTotalUFFocus(false)}
                formatter={(value) =>
                  dividendoTotalUFFocus ? value : numberWithPoints(value)
                }
              />
            </Form.Item>
          </Tooltip>
        </span>
      </Col>
    </Row>
  );
};

export default MainTable;
