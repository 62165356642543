// Importar librerías
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import _ from 'lodash';
import { Spin, Tabs, Tag, message } from 'antd';
import { useDocument } from 'react-firebase-hooks/firestore';

// Importar constantes
import { appOptionsNames } from '../../constants/appTypes';

// Importar otros componentes
import { ReactComponent as BackIcon } from './assets/back.svg';
import ApplicationAnswerIndex from './ApplicationAnswer/ApplicationAnswerIndex';
import ApplicationData from './ApplicationData';
import TakenApplicationChat from '../TakenApplications/TakenApplicationChat';

// Importar rutas
import * as ROUTES from '../../constants/routes';

// Importar utilidades
import { withFirebase, withAuthUser } from '../../utils';

// Importar subcomponente TabPane
const { TabPane } = Tabs;

// Definir formato de fecha
const DATE_FORMAT = 'DD/MM/YYYY, HH:mm';

// Definir parámetros de filtrado
const paramToValue = {
  cuota: 'cuotaMensual1',
  credito: 'costoTotal1',
};

/**
 *
 * @param {*} endDate
 * Descompone una fecha en días, horas y minutos.
 */
const getRemainingTime = (endDate) => {
  const now = moment(Date.now());
  const momentEndDate = moment(endDate.toDate());

  const days = momentEndDate.diff(now, 'days');
  const hours = momentEndDate.diff(now, 'hours') - days * 24;
  const minutes = momentEndDate.diff(now, 'minutes') - days * 24 * 60 - hours * 60;
  return { days, hours, minutes };
};

/**
 *
 * @param {*} applicationDoc
 * @param {*} authUser
 * Verifica si un ejecutivo puede ver una solicitud.
 */
const showApplicationCalc = (applicationDoc, authUser) => {
  // Validar que existan documentos
  if (applicationDoc && !applicationDoc.exists) {
    return false;
  }

  // NOTA: Un admin la puede ver
  if (authUser.roles.BANKADMIN === 'BANKADMIN') return true;

  // Función para ver si este ejecutivo puede efectivamente ver la cotización actual
  let showApplication = true;
  if (
    applicationDoc &&
    !applicationDoc
      .data()
      .executives.map((d) => d.id)
      .includes(authUser.uid)
  ) {
    showApplication = false;
  }

  return showApplication;
};

const ApplicationItem = ({ authUser, firebase, ...props }) => {
  // Obtener id de la cotización
  const applicationId = props.match.params.id;

  // Hacer consulta a la BD
  const [applicationDoc, loading, error] = useDocument(
    firebase.applicationLogic.application(applicationId),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  // Definir state
  const [applicationLoaded, setApplicationLoaded] = useState(false);
  const [answerLoading, setAnswerLoading] = useState(false);
  const [activeTab, setActiveTab] = useState('application');
  const [showAdditionalTabs, setShowAdditionalTabs] = useState(false);

  // Validar si el ejecutivo puede ver la cotización actual
  const showApplication = showApplicationCalc(applicationDoc, authUser);

  // Definir effect para setear el valor de applicationLoaded
  useEffect(() => {
    if (error) {
      setApplicationLoaded(false);
    }
    if (loading) {
      setApplicationLoaded(false);
    } else {
      if (applicationDoc.exists) {
        // Setear carga de la aplicación
        setApplicationLoaded(true);

        // Habilitar chat si la oferta de este ejcutivo es la mejor
        if (
          applicationDoc.get('status') !== 'accepted' &&
          !_.isEmpty(
            applicationDoc.data().answers.filter((a) => a.status !== 'noOffer')
          ) &&
          Date.now() > applicationDoc.get('endDate').toDate().getTime() &&
          moment(applicationDoc.get('endDate').toDate())
            .add(3, 'd')
            .isAfter(moment())
        ) {
          let param = null;
          if (
            applicationDoc.get('type') === 'consumo' ||
            applicationDoc.get('type') === 'portabilidad-consumo'
          ) {
            param = paramToValue[applicationDoc.get('bestAnswerParam')];
          } else {
            param = applicationDoc.get('bestAnswerParam');
          }
          const selectedAnswer = applicationDoc
            .data()
            .answers.filter((a) => a.status !== 'noOffer')
            .reduce((prev, current) =>
              prev.answer[param] > current.answer[param] ? current : prev
            );
          if (selectedAnswer.executiveId === authUser.uid) {
            // Habilitar chat
            setShowAdditionalTabs(true);

            // Obtener información del chat
            const chat = applicationDoc.get('chat');

            // Mostrar una alerta al ejecutivo en caso de que tenga un nuevo mensaje del usuario
            if (
              chat.length >= 2 &&
              chat[chat.length - 1].senderId !== selectedAnswer.executiveId
            ) {
              message.info(
                `En el chat, tienes nuevos mensajes de ${
                  applicationDoc.get('user').firstName
                } ${applicationDoc.get('user').lastName}.`
              );
            }
          }
        }
      } else {
        setApplicationLoaded(false);
      }
    }
  }, [applicationDoc, loading, error, authUser]);

  // Definir effect para redireccionar al usuario en caso de que no se le esté
  // permitido ver la cotización
  useEffect(() => {
    if (!showApplication) {
      props.history.push(ROUTES.HOME);
    }
  });

  // Obtener estado de la cotización
  let rejected = false;
  if (applicationLoaded) {
    const status = applicationDoc.get('status');
    if (status === 'rejected' || status === 'bankRejected') rejected = true;
  }

  // Cerrado si endDate ya paso => endDate > now
  const open = applicationLoaded
    ? applicationDoc.get('endDate').toDate().getTime() > Date.now()
    : null;

  // Obtener booleanos para editar y ver la cotización según sea el role del usuario
  const canEdit = open && authUser.roles.BANKEXECUTIVE === 'BANKEXECUTIVE';
  const canSeeAnswer = authUser.roles.BANKEXECUTIVE === 'BANKEXECUTIVE';

  // Obtener información de la cotización una vez se haya cargado
  const application = applicationLoaded ? applicationDoc.data() : null;

  // Validar tiempo restante para dar respuesta a la cotización
  const remainingTime = applicationLoaded
    ? getRemainingTime(applicationDoc.get('endDate'))
    : null;

  // Obtener tipo de la cotización
  const applicationType = applicationLoaded ? applicationDoc.get('type') : null;

  // Renderizar componente
  return (
    <Spin spinning={loading || answerLoading}>
      <div>
        {error && <strong>Error: {JSON.stringify(error)}</strong>}
        {loading && <span>Document: Loading...</span>}
        {applicationLoaded && showApplication && (
          <div className="application-show">
            <div className="application-header">
              <div className="application-type">
                {appOptionsNames[applicationType]}
                <div className="back">
                  {authUser.roles.BANKEXECUTIVE === 'BANKEXECUTIVE' ? (
                    <Link to={ROUTES.APPLICATIONS_PAGE}>
                      <BackIcon /> Volver a mis solicitudes
                    </Link>
                  ) : (
                    <Link to={ROUTES.ASSIGN_LIST_PAGE}>
                      <BackIcon /> Volver a asignación
                    </Link>
                  )}
                </div>
              </div>
              <div className="main-info">
                <div className="title">
                  <div className={'top'}>
                    <span className="name">{applicationDoc.get('nombre')}</span>
                    <span className="createdAt">
                      {moment(applicationDoc.get('createdAt').toDate()).format(
                        DATE_FORMAT
                      )}{' '}
                      hrs
                    </span>
                  </div>
                  <div className={'bottom'}>
                    <span className="userName">
                      {`${applicationDoc.get('user').firstName} ${
                        applicationDoc.get('user').lastName
                      }`}
                    </span>
                    <span className={'rut'}>{applicationDoc.get('rut')}</span>
                    <span className={'location'}>
                      {applicationDoc.get('region')}
                      {' - '} {applicationDoc.get('comuna')}
                    </span>
                  </div>
                </div>
                <div className="extra">
                  <div className="time-limit">
                    <span className="bold">
                      Plazo de Respuesta {open ? 'abierto' : 'cerrado'}
                    </span>
                    {open && (
                      <span className="remaining">
                        Quedan {remainingTime.days}d {remainingTime.hours}h{' '}
                        {remainingTime.minutes}m
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div className="labels">
                {!rejected && <Tag color={'#E3D7FE'}>En Trámite</Tag>}
                {rejected && <Tag color={'#FBD3DF'}>Oferta Rechazada</Tag>}
                <Tag className="bold" color={'#05035811'}>
                  {application.idCostQuote || applicationDoc.id}
                </Tag>
              </div>
            </div>

            <Tabs activeKey={activeTab} onTabClick={setActiveTab}>
              <TabPane tab={'Solicitud'} key="application">
                <ApplicationData
                  application={application}
                  applicationType={applicationType}
                />
              </TabPane>
              <TabPane tab={'Oferta'} key="answer" disabled={!canSeeAnswer}>
                <ApplicationAnswerIndex
                  application={applicationDoc}
                  applicationType={applicationType}
                  answerLoadingStatus={{ answerLoading, setAnswerLoading }}
                  canEdit={canEdit}
                />
              </TabPane>
              <TabPane
                tab={'Chat Cliente'}
                key="chat"
                disabled={!showAdditionalTabs}
              >
                <TakenApplicationChat
                  application={applicationDoc}
                  authUser={authUser}
                  firebase={firebase}
                  rejected={false}
                />
              </TabPane>
            </Tabs>
          </div>
        )}
      </div>
    </Spin>
  );
};

export default withAuthUser(withFirebase(ApplicationItem));
