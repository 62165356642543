// Importar librerías
import React, { useState, useContext } from 'react';
import { Modal, Button, Form, Input, message, Upload, Tag } from 'antd';
import { PaperClipOutlined } from '@ant-design/icons';

// Importar context de firebase y user
import { FirebaseContext, AuthUserContext } from './../../context';

// Importar hooks
import useWindowSize from '../../hooks/useWindowSize';

const NewRequirementModal = ({
  openNewRequirementModal,
  setOpenNewRequirementModal,
  application,
  setRequirements,
}) => {
  // Definir state
  const [loading, setLoading] = useState(false);
  const [newRequirementFile, setNewRequirementFile] = useState({});

  // Obtener context de firebase y user
  const firebase = useContext(FirebaseContext);
  const authUser = useContext(AuthUserContext);

  // Obtener tamaño de la ventana
  const windowWidth = useWindowSize();

  // Definir nueva instancia de useForm
  const [form] = Form.useForm();

  /**
   * Permite cerrar un modal.
   */
  const handleCancel = () => {
    setOpenNewRequirementModal(false);
  };

  /**
   *
   * @param {*} values
   * Almacena la información en la BD de los requerimientos de la oferta.
   */
  const onFinish = (values) => {
    setLoading(true);
    firebase.applicationLogic
      .addRequirementMessage(
        authUser.uid,
        application.id,
        values.message,
        values.title,
        newRequirementFile
      )
      .then(() => {
        setLoading(false);
        setOpenNewRequirementModal(false);

        setRequirements(application.get('requirements'));
        form.resetFields();
        setNewRequirementFile({});
      })
      .catch((e) => {
        message.error('Algo salió mal');
        setLoading(false);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
    message.error('Error');
  };

  // Renderizar componente
  return (
    <Modal
      visible={openNewRequirementModal}
      onOk={onFinish}
      confirmLoading={loading}
      onCancel={handleCancel}
      width={windowWidth < 768 ? 350 : 520}
      footer={
        <div id="requirement-modal-footer">
          <Button
            loading={loading}
            form="modal-new-requirement-form"
            key="submit"
            htmlType="submit"
            type={'primary'}
          >
            Aceptar
          </Button>
        </div>
      }
    >
      <div id="requirement-new-modal-body">
        <h3>Agregar un nuevo requerimiento</h3>

        <Form
          name="requirement_form"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          id="modal-new-requirement-form"
          form={form}
          layout="vertical"
          hideRequiredMark={true}
        >
          <Form.Item
            label="Título del mensaje"
            name="title"
            rules={[{ required: true, message: 'Agregar un titulo' }]}
          >
            <Input disabled={loading} />
          </Form.Item>
          <Form.Item
            label="Mensaje"
            name="message"
            rules={[{ required: true, message: 'Agregar un mensaje' }]}
          >
            <Input.TextArea disabled={loading} />
          </Form.Item>
          <Upload
            showUploadList={false}
            onChange={(e) => setNewRequirementFile(e.file)}
            className={'link'}
          >
            Adjuntar archivo
            <PaperClipOutlined />
          </Upload>
          {newRequirementFile.name && (
            <Tag closable onClose={() => setNewRequirementFile({})}>
              {newRequirementFile.name}
            </Tag>
          )}
        </Form>
      </div>
    </Modal>
  );
};

export default NewRequirementModal;
