// Importar librerías
import React, { useState } from 'react';
import { Modal, Button, Form, Input, message, Upload, Tag } from 'antd';
import { PaperClipOutlined } from '@ant-design/icons';

// Importar hooks
import useWindowSize from '../../hooks/useWindowSize';

const NewCommonRequirementModal = ({
  openNewRequirementModal,
  setOpenNewRequirementModal,
  firebase,
  authUser,
}) => {
  // Definir state
  const [loading, setLoading] = useState(false);
  const [newRequirementFile, setNewRequirementFile] = useState({});

  // Definir nueva instancia de useForm
  const [form] = Form.useForm();

  // Obtener tamaño de la ventana
  const windowWidth = useWindowSize();

  /**
   *
   * @param {*} values
   * Almacena la información en la BD cuando se haya llenado toda la información del formulario.
   */
  const onFinish = async (values) => {
    // Habilitar carga del spinner
    setLoading(true);

    // Agregar información adicional a los valores de entrada
    values = {
      ...values,
      executiveId: authUser.uid,
      newRequirementFile,
    };

    // Intentar almacenar en la BD
    try {
      const newCommonRequirement = await firebase.applicationLogic.addCommonRequirement(
        values
      );

      // Almacenar requerimiento en el local
      let oldArray = JSON.parse(localStorage.getItem('commonRequirements'));
      oldArray.push(newCommonRequirement);
      localStorage.setItem('commonRequirements', JSON.stringify(oldArray));

      // Mostrar mensaje de éxito
      message.success('Requerimiento agregado correctamente');

      // Inicializar valores
      form.resetFields();
      setNewRequirementFile({});
      setOpenNewRequirementModal(false);
    } catch (error) {
      message.error(error.message);
    }

    // Deshabilitar carga del spinner
    setLoading(false);
  };

  /**
   *
   * @param {*} errorInfo
   * Muestra un mensaje de error si el ejecutivo de banco no se ha creado correctamente.
   */
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  // Renderizar componente
  return (
    <Modal
      onCancel={() => {
        form.resetFields();
        setOpenNewRequirementModal(false);
      }}
      confirmLoading={loading}
      footer={
        <div id="requirement-modal-footer">
          <Button
            loading={loading}
            form="modal-new-requirement-form"
            key="submit"
            htmlType="submit"
            type={'primary'}
          >
            Aceptar
          </Button>
        </div>
      }
      onOk={onFinish}
      width={windowWidth < 992 ? 350 : 520}
      visible={openNewRequirementModal}
    >
      <div id="requirement-new-modal-body">
        <h3 className="small-size">Agregar requerimiento común</h3>
        <Form
          name="requirement_form"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          id="modal-new-requirement-form"
          form={form}
          layout="vertical"
          hideRequiredMark={true}
        >
          <Form.Item
            label="Título del mensaje"
            name="title"
            rules={[{ required: true, message: 'Agregar un titulo' }]}
          >
            <Input disabled={loading} />
          </Form.Item>
          <Form.Item
            label="Mensaje"
            name="message"
            rules={[{ required: true, message: 'Agregar un mensaje' }]}
          >
            <Input.TextArea disabled={loading} />
          </Form.Item>
          <Upload
            showUploadList={false}
            onChange={(e) => setNewRequirementFile(e.file)}
            className={'link'}
          >
            Adjuntar archivo
            <PaperClipOutlined />
          </Upload>
          {newRequirementFile.name && (
            <Tag closable onClose={() => setNewRequirementFile({})}>
              {newRequirementFile.name}
            </Tag>
          )}
        </Form>
      </div>
    </Modal>
  );
};

export default NewCommonRequirementModal;
