// Importar librerías
import React, { useState, useEffect } from 'react';
import { Divider, Table, Button, Space, Modal, message, Tooltip } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

// Importar otros componentes
import NewCommonRequirementModal from './NewCommonRequirementModal';

// Importar utilidades
import { withFirebase } from '../../utils';

// Importar hooks
import useWindowSize from '../../hooks/useWindowSize';

// Importar subcomoponente confirm
const { confirm } = Modal;

const RequirementsList = ({ authUser, firebase }) => {
  // Definir state
  const [openNewRequirementModal, setOpenNewRequirementModal] = useState(false);
  const [reloadPage, setReloadPage] = useState(null);

  // Definir effect para recargar la página
  useEffect(() => {
    if (reloadPage) {
      message.success('Requerimiento eliminado correctamente');
      setReloadPage(null);
    }
  }, [reloadPage]);

  // Obtener tamaño de la ventana
  const windowWidth = useWindowSize();

  /**
   *
   * @param {*} _
   * @param {*} row
   * Rederiza un componente con las acciones a realizar en el listado de cotizaciones.
   */
  const renderActions = (_, row) => {
    return (
      <Space>
        <DeleteOutlined onClick={() => handleCommonRequirementDelete(row)} />
      </Space>
    );
  };

  /**
   *
   * @param {*} file
   * Abre un archivo de descarga.
   */
  const handleFileClick = async (file) => {
    message.info('Obteniendo archivo del servidor');
    const url = await firebase.getDownloadURL(file);
    window.open(url);
  };

  // Definir columnas de la tabla
  const columns = [
    {
      title: 'ARCHIVO',
      key: 'file',
      dataIndex: 'file',
      render: (_, row) => {
        if (!row.file) return 'No Adjuntado';
        return (
          <span
            className="view-executive-file"
            onClick={() => handleFileClick(row.file)}
          >
            {row.file.split('/')[1]}
          </span>
        );
      },
      sorter: (a, b) => {
        let documentA = 'No Adjuntado',
          documentB = 'No Adjuntado';
        if (a.file) {
          documentA = a.file.split('/')[1];
        }
        if (b.file) {
          documentA = b.file.split('/')[1];
        }
        return documentA.localeCompare(documentB);
      },
      show: true,
    },
    {
      title: 'TÍTULO',
      key: 'title',
      dataIndex: 'title',
      sorter: (a, b) => a.title.localeCompare(b.title),
      show: true,
    },
    {
      title: 'MENSAJE',
      key: 'message',
      dataIndex: 'message',
      render: (text) => {
        return (
          <Tooltip title={text}>
            <p className="summary">{text}</p>
          </Tooltip>
        );
      },
      sorter: (a, b) => a.message.localeCompare(b.message),
      show: true,
    },
    {
      title: 'ACCIONES',
      key: 'actions',
      render: renderActions,
      show: true,
    },
  ];

  /**
   *
   * @param {*} row
   * Elimina un requerimiento común.
   */
  const handleCommonRequirementDelete = (row) => {
    confirm({
      title: '¿Estás seguro que deseas eliminar este requerimiento?',
      icon: <ExclamationCircleOutlined />,
      content: 'Esta acción es irreversible',
      width: windowWidth < 768 ? 350 : 420,
      okText: 'Sí',
      cancelText: 'No',
      async onOk() {
        try {
          // Definir valores
          const values = {
            ...row,
            executiveId: authUser.uid,
          };

          // Intentar eliminar de la BD
          const deletedCommonRequirement = await firebase.applicationLogic.deleteCommonRequirement(
            values
          );

          // Actualizar state
          const oldArray = JSON.parse(localStorage.getItem('commonRequirements'));
          const newArray = oldArray.filter(
            (item) => item.title !== deletedCommonRequirement.title
          );
          localStorage.setItem('commonRequirements', JSON.stringify(newArray));

          // Setear reload
          setReloadPage(true);

          // Devolver mensaje de éxito
          return null;
        } catch (error) {
          return message.error(error.message);
        }
      },
    });
  };

  // Renderizar componente
  return (
    <>
      <div id="executives-head">
        <h1> Requerimientos comunes de las solicitudes</h1>
        <Button
          icon={<PlusOutlined />}
          type={'primary'}
          onClick={() => setOpenNewRequirementModal(true)}
        >
          Agregar
        </Button>
      </div>
      <NewCommonRequirementModal
        openNewRequirementModal={openNewRequirementModal}
        setOpenNewRequirementModal={setOpenNewRequirementModal}
        firebase={firebase}
        authUser={authUser}
      />
      <Divider />
      <div id="executives-main-table">
        <Table
          columns={columns.filter((item) => item.show)}
          pagination={false}
          dataSource={JSON.parse(localStorage.getItem('commonRequirements')) || []}
          size={windowWidth < 768 ? 'small' : 'large'}
          rowKey={(r) => r.title + r.message}
        />
      </div>
    </>
  );
};

export default withFirebase(RequirementsList);
