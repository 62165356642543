// Importar librerías
import React, { useState, useContext } from 'react';
import { Modal, Button, message } from 'antd';

// Importar context de firebase
import { FirebaseContext } from '../../context';

// Importar hooks
import useWindowSize from '../../hooks/useWindowSize';

const DeleteExecutiveModal = ({
  openDeleteExecutiveModal,
  setOpenDeleteExecutiveModal,
  openModalRecord,
}) => {
  // Definir context de firebase
  const firebase = useContext(FirebaseContext);

  // Definir state
  const [loading, setLoading] = useState(false);

  // Obtener tamaño de la ventana
  const windowWidth = useWindowSize();

  // Aplicar destructuración para obtener la información del ejecutivo
  const {
    firstName,
    lastName,
    bankName,
    bankId,
    bankOfficeId,
    email,
    executiveId,
  } = openModalRecord;

  /**
   * Elimina un ejecutivo de la sucursal o banco actual.
   */
  const removeExecutive = async () => {
    // Habilitar carga del spinner
    setLoading(true);

    // Definir objeto con los valores de la BD
    const values = {
      email,
      executiveId,
      bankId,
      bankOfficeId,
    };

    // Intentar eliminar de la BD
    try {
      await firebase.executiveLogic.executiveDelete(values);

      // Mostrar mensaje de éxito
      message.success('Ejecutivo eliminado correctamente');

      // Setear información del modal
      setOpenDeleteExecutiveModal(false);
    } catch (error) {
      message.error(error.message);
    }

    // Deshabilitar spinner
    setLoading(false);
  };

  // Renderizar componente
  return (
    <Modal
      visible={openDeleteExecutiveModal}
      onCancel={() => {
        setOpenDeleteExecutiveModal(false);
      }}
      width={windowWidth < 992 ? 350 : 520}
      confirmLoading={loading}
      footer={
        <div id="executive-modal-footer">
          <Button onClick={() => removeExecutive()} type={'primary'}>
            Eliminar
          </Button>
        </div>
      }
    >
      <div id={'executive-show-modal-body'}>
        <h3>
          ¿Seguro que quieres eliminar a {firstName} {lastName}?
        </h3>
        <span id={'text'}>
          Se eliminará a {firstName} {lastName} del {bankName}. Cualquier respuesta
          que este haya dado a una solicitud quedará de parte del banco hasta que se
          asigne otro ejecutivo para estas.
          <br />
          Puede que esta acción tarde un poco en mostrarse como completada.
        </span>
      </div>
    </Modal>
  );
};

export default DeleteExecutiveModal;
