// Importar librerías
import React from 'react';
import { Button, Modal, Select } from 'antd';

const DropDownExecutives = ({
  executives,
  application,
  authUser,
  setSelectedExecutive,
}) => {
  // Validar que exista información de los ejecutivos y las cotización
  if (!executives) return <p>No hay ejecutivos disponibles</p>;
  if (!application) return <div></div>;

  // Filtrar ejecutivos si es el caso
  let newExecutives = [];
  newExecutives = executives.filter((exec) =>
    exec.bankOfficeAppTypes.includes(application.get('type'))
  );

  /**
   * Muestra la información de un ejecutivo.
   */
  const options = newExecutives.map((d) => {
    return { label: `${d.firstName} ${d.lastName}`, value: d.email };
  });

  // Renderizar componente
  return (
    <div id={'select-executive'}>
      <Select
        showSearch
        allowClear
        placeholder={'Elige a un ejecutivo'}
        options={options}
        onChange={(e) => {
          setSelectedExecutive(e);
        }}
      />
    </div>
  );
};

const AssignModal = ({
  setShowAssign,
  setAssignApplication,
  setSelectedExecutive,
  confirmLoading,
  assignExecutive,
  assignApplication,
  selectedExecutive,
  setconfirmLoading,
  authUser,
  firebase,
  showAssign,
  executivesFull,
  windowWidth,
}) => {
  // Renderizar componente
  return (
    <Modal
      onCancel={() => {
        setShowAssign(false);
        setAssignApplication(undefined);
        setSelectedExecutive(undefined);
      }}
      confirmLoading={confirmLoading}
      width={windowWidth < 992 ? 350 : 520}
      footer={
        <div id="assign-modal-footer">
          <Button
            loading={confirmLoading}
            type={'primary'}
            onClick={() =>
              assignExecutive(
                assignApplication,
                setAssignApplication,
                selectedExecutive,
                setSelectedExecutive,
                setconfirmLoading,
                setShowAssign,
                authUser,
                firebase
              )
            }
          >
            Aceptar
          </Button>
        </div>
      }
      visible={showAssign}
    >
      <div id="assign-modal-body">
        <h3>Asignar solicitud a un ejecutivo</h3>

        <p>Selecciona a que ejecutivo quieres asignar la siguiente solicitud</p>
        <DropDownExecutives
          executives={executivesFull}
          application={assignApplication}
          authUser={authUser}
          selectedExecutive={selectedExecutive}
          setSelectedExecutive={setSelectedExecutive}
        />
      </div>
    </Modal>
  );
};

export default AssignModal;
