// Importar librerías
import React, { useContext } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { Layout } from 'antd';

// Importar otros componentes
import CustomSider from './Layout/CustomSider';
import CustomHeader from './Layout/CustomHeader';
import SignInPage from './Session/signIn';
import SignOut from './Session/SignOut';
import PasswordForgetPage from './Session/passwordForget';
import HomePage from './Home';
import ExecutivesPage from './Executives';
import AssignApplicationsPage from './AssignApplications';
import ApplicationsPage from './Applications';
import TakenApplicationsPage from './TakenApplications';
import RequirementsPage from './Requirements';

// Importar rutas, roles y decoradores
import * as ROUTES from '../constants/routes';
import { bankGeneralUserCondition } from '../constants/conditions';
import { withAuthentication, withHardAuthorization } from '../utils';

// Importar context de user
import { AuthUserContext } from '../context';

// Importar hooks
import useWindowSize from '../hooks/useWindowSize';

// Importar subcomponente Content
const { Content } = Layout;

// Definir rutas públicas
const PublicLayout = () => (
  <Switch>
    <Route path={ROUTES.SIGN_IN} component={SignInPage} />
    <Route path={ROUTES.SIGN_OUT} component={SignOut} />
    <Route path={ROUTES.PASSWORD_FORGET} component={PasswordForgetPage} />
  </Switch>
);

// Definir rutas privadas
const PrivateLayout = () => (
  <Layout>
    <CustomHeader />
    <Content className="content">
      <Switch>
        <Route path={ROUTES.HOME} component={HomePage} />
        <Route path={ROUTES.EXECUTIVES_PAGE} component={ExecutivesPage} />
        <Route path={ROUTES.ASSIGN_LIST_PAGE} component={AssignApplicationsPage} />
        <Route path={ROUTES.APPLICATIONS_PAGE} component={ApplicationsPage} />
        <Route
          path={ROUTES.TAKEN_APPLICATIONS_PAGE}
          component={TakenApplicationsPage}
        />
        <Route path={ROUTES.REQUIREMENTS_PAGE} component={RequirementsPage} />
      </Switch>
    </Content>
  </Layout>
);

const App = () => {
  // Obtener información del context de user
  const authUser = useContext(AuthUserContext);

  // Obtener ancho de la ventana
  const windowWidth = useWindowSize();

  // Renderizar
  return (
    <Router>
      <Layout
        style={{ minHeight: '100vh', width: '100%' }}
        hasSider={windowWidth < 992 ? false : true}
      >
        {/* Se renderea segun la ruta  */}
        <CustomSider authUser={authUser} />
        <Content className="main-content">
          <Switch>
            <Route exact path={ROUTES.LANDING}>
              {authUser ? (
                <Redirect to={ROUTES.HOME} />
              ) : (
                <Redirect to={ROUTES.SIGN_IN} />
              )}
            </Route>
            <Route path={ROUTES.SIGN_IN} component={PublicLayout} />
            <Route path={ROUTES.SIGN_OUT} component={PublicLayout} />
            <Route path={ROUTES.PASSWORD_FORGET} component={PublicLayout} />
            <Route
              path="/"
              component={withHardAuthorization(bankGeneralUserCondition)(
                PrivateLayout
              )}
            />
          </Switch>
        </Content>
      </Layout>
    </Router>
  );
};

export default withAuthentication(App);
