export const appOptionsExecutive = {
  consumo: { value: 'consumo', label: 'Consumo' },
  hipotecario: { value: 'hipotecario', label: 'Hipotecario' },
  automotriz: { value: 'automotriz', label: 'Automotriz' },
  'portabilidad-consumo': {
    value: 'portabilidad-consumo',
    label: 'Portabilidad Consumo',
  },
  'portabilidad-hipotecario': {
    value: 'portabilidad-hipotecario',
    label: 'Portabilidad Hipotecario',
  },
};

export const appOptionsNames = {
  consumo: 'CRÉDITO DE CONSUMO',
  hipotecario: 'CRÉDITO HIPOTECARIO',
  automotriz: 'CRÉDITO AUTOMOTRIZ',
  'portabilidad-consumo': 'PORTABILIDAD CRÉDITO DE CONSUMO',
  'portabilidad-hipotecario': 'PORTABILIDAD CRÉDITO HIPOTECARIO',
};
