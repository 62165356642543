class authenticationLogic {
  constructor(app, auth) {
    this.auth = auth;
    this.googleProvider = new app.auth.GoogleAuthProvider();
    this.facebookProvider = new app.auth.FacebookAuthProvider();
    this.twitterProvider = new app.auth.TwitterAuthProvider();
  }

  // *** Auth API ***

  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSignInWithGoogle = () => this.auth.signInWithPopup(this.googleProvider);

  doSignInWithFacebook = () => this.auth.signInWithPopup(this.facebookProvider);

  doSignInWithTwitter = () => this.auth.signInWithPopup(this.twitterProvider);

  doSignOut = () => this.auth.signOut();

  doPasswordReset = (email) => this.auth.sendPasswordResetEmail(email);

  doSendEmailVerification = () => {
    let url_redirect;
    switch (process.env.REACT_APP_ENV) {
      case 'dev':
        url_redirect = process.env.REACT_APP_DEV_CONFIRMATION_EMAIL_REDIRECT;
        break;
      case 'staging':
        url_redirect = process.env.REACT_APP_STAGE_CONFIRMATION_EMAIL_REDIRECT;

        break;
      case 'prod':
        url_redirect = process.env.REACT_APP_PROD_CONFIRMATION_EMAIL_REDIRECT;
        break;

      default:
        break;
    }

    return this.auth.currentUser.sendEmailVerification({
      url: url_redirect,
    });
  };

  doPasswordUpdate = (password) => this.auth.currentUser.updatePassword(password);
}

export default authenticationLogic;
