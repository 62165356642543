// Importar librerías
import React, { useContext } from 'react';
import { Redirect } from 'react-router-dom';

// Importar context de firebase
import { FirebaseContext } from '../../context';

// Importar rutas
import * as ROUTES from '../../constants/routes';

const SignOut = () => {
  // Obtener información del context
  const firebase = useContext(FirebaseContext);

  // Desloguear al usuario
  firebase.authLogic.doSignOut();

  // Limpiar localStorage;
  localStorage.removeItem('commonRequirements');

  // Renderizar
  return <Redirect to={ROUTES.SIGN_IN} />;
};

export default SignOut;
