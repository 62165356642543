// Importar librerías
import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { Layout } from 'antd';

// Importar rutas
import * as ROUTES from './../../constants/routes';

// Importar context de user
import { AuthUserContext } from './../../context';

// Importar hooks
import useWindowSize from '../../hooks/useWindowSize';

// Importar subcomponente Header
const { Header } = Layout;

// Definir objeto cuya clave es la ruta y su valor es un string
const locationNames = {};
locationNames[ROUTES.ASSIGN_LIST_PAGE] = () => 'Asignar Solicitudes';
locationNames[ROUTES.EXECUTIVES_PAGE] = () => 'Ejecutivos del Banco';
locationNames[ROUTES.HOME] = (name) => `¡Hola ${name}!`;
locationNames[ROUTES.ACCOUNT] = () => 'Cuenta';
locationNames[ROUTES.APPLICATIONS_PAGE] = () => 'Asignadas';
locationNames[ROUTES.TAKEN_APPLICATIONS_PAGE] = () => 'Adjudicadas';
locationNames[ROUTES.REQUIREMENTS_PAGE] = () => 'Precargar Requerimientos';

const CustomHeader = () => {
  // Definir nueva instancia de location
  const location = useLocation();

  // Obtener context del user
  const authUser = useContext(AuthUserContext);

  // Obtener ancho de la ventana
  const windowWidth = useWindowSize();

  // Renderizar componente
  return (
    <Header id="header">
      <div className="site-name-2">
        <p>
          {locationNames['/' + location.pathname.split('/')[1]]
            ? locationNames['/' + location.pathname.split('/')[1]](
                authUser.firstName
              )
            : ''}
        </p>
      </div>
      {windowWidth >= 768 && (
        <div className="account-info-2">
          <p>{authUser.firstName + ' ' + authUser.lastName}</p>
        </div>
      )}
    </Header>
  );
};
export default CustomHeader;
