// Importar librerías
import { useState, useEffect } from 'react';

// Definir función para obtener el tamaño de la ventana
const getWidth = () =>
  window.innerWidth ||
  document.documentElement.clientWidth ||
  document.body.clientWidth;

const useWindowSize = () => {
  // Definir state local
  let [width, setWidth] = useState(getWidth());

  // Defini useEffet para obtener el valor de la ventana
  useEffect(() => {
    // Definir tiempo de ejecución de la función
    let timeoutId = null;

    // Definir función que va a obtener el valor de la ventana
    const resizeListener = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => setWidth(getWidth()), 150);
    };

    // Habilitar evento
    window.addEventListener('resize', resizeListener);

    // Limpiar evento
    return () => {
      window.removeEventListener('resize', resizeListener);
    };
  }, []);

  // Retonar ancho de la ventana
  return width;
};

// Exportar hook
export default useWindowSize;
