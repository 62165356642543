// Importar librerías
import React, { useState, useContext } from 'react';
import { Modal, Button, Form, Input, message } from 'antd';

// Importar context de firebase y user
import { FirebaseContext, AuthUserContext } from './../../../context';

// Importar hooks
import useWindowSize from '../../../hooks/useWindowSize';

const RejectModal = ({ openModal, setOpenModal, application }) => {
  // Definir state
  const [loading, setLoading] = useState(false);

  // Definir context de firebase y user
  const firebase = useContext(FirebaseContext);
  const authUser = useContext(AuthUserContext);

  // Definir nueva instancia de useForm
  const [form] = Form.useForm();

  // Obtener tamaño de la ventana
  const windowWidth = useWindowSize();

  /**
   * Permite cerrar un modal.
   */
  const handleCancel = () => {
    setOpenModal(false);
  };

  /**
   *
   * @param {*} values
   * Almacena la información en la BD del rechazo de la oferta.
   */
  const onFinish = (values) => {
    setLoading(true);
    return firebase.applicationLogic
      .rejectAnswer(application.id, values.motive)
      .then((res) => {
        setLoading(false);
        message.success('Solicitud rechazada con exito');
        setOpenModal(false);
        return true;
      })
      .then(() => {
        const user = application.get('user');

        const emailBody = `

        Estimado ${user.firstName}:

        Tu ejecutivo del ${
          authUser.bankName
        } ha decidido rechazar tu crédito ${application.get(
          'nombre'
        )} por la siguiente razón:

        ${values.motive}

      Equipo Flit
        `;

        return firebase.functions.httpsCallable('sendInfoMailer')({
          email: application.get('user').email,
          body: emailBody,
        });
      })
      .catch((e) => {
        setLoading(false);
        message.error('Error, intente nuevamente');
      });
  };

  /**
   *
   * @param {*} errorInfo
   * Maneja posibles errores al momento de rechazar una solicitud.
   */
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
    message.error('Error');
  };

  // Renderizar componente
  return (
    <Modal
      visible={openModal}
      onOk={onFinish}
      confirmLoading={loading}
      onCancel={handleCancel}
      width={windowWidth < 768 ? 350 : 520}
      footer={
        <div id="reject-modal-footer">
          <Button
            loading={loading}
            form="modal-new-reject-form"
            key="submit"
            htmlType="submit"
            type={'primary'}
          >
            Enviar
          </Button>
        </div>
      }
    >
      <div id="reject-new-modal-body">
        <h3>Rechazar Oferta</h3>

        <Form
          name="reject_form"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          id="modal-new-reject-form"
          form={form}
          layout="vertical"
          hideRequiredMark={true}
        >
          <Form.Item
            label="Motivo del Rechazo"
            name="motive"
            rules={[{ required: true, message: 'Agregar un motivo' }]}
          >
            <Input.TextArea disabled={loading} />
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

export default RejectModal;
