// Importar librerías
import React, { useState } from 'react';
import { message } from 'antd';

// Importar otros componentes
import TakenApplicationAnswerShowHipotecario from './TakenApplicationAnswerShowHipotecario';
import TakenApplicationAnswerShowConsumo from './TakenApplicationAnswerShowConsumo';
import RejectModal from './RejectModal';
import ReportModal from './ReportModal';

const TakenApplicationAnswerShowIndex = ({
  applicationType,
  application,
  rejected,
}) => {
  // Definir state
  const [rejectOpen, setRejectOpen] = useState(false);
  const [reportOpen, setReportOpen] = useState(false);

  /**
   * Valida si una solicitud ya fue rechazada.
   */
  const reject = () => {
    if (!rejected) {
      setRejectOpen(true);
    } else {
      message.error('Ya rechazaste esta solicitud');
    }
  };

  /**
   * Cambia a true el estado de reportOpen
   */
  const report = () => setReportOpen(true);

  /**
   * Renderizar un modal según sea el caso.
   */
  switch (applicationType) {
    case 'portabilidad-hipotecario':
    case 'hipotecario':
      return (
        <>
          <RejectModal
            openModal={rejectOpen}
            setOpenModal={setRejectOpen}
            application={application}
          />
          <ReportModal
            openModal={reportOpen}
            setOpenModal={setReportOpen}
            application={application}
          />

          <TakenApplicationAnswerShowHipotecario
            application={application}
            reject={reject}
            report={report}
          />
        </>
      );
    case 'portabilidad-consumo':
    case 'consumo':
    case 'automotriz':
      return (
        <>
          <RejectModal
            openModal={rejectOpen}
            setOpenModal={setRejectOpen}
            application={application}
          />
          <ReportModal
            openModal={reportOpen}
            setOpenModal={setReportOpen}
            application={application}
          />
          <TakenApplicationAnswerShowConsumo
            application={application}
            reject={reject}
            report={report}
          />
        </>
      );

    default:
      return <div>Error</div>;
  }
};

export default TakenApplicationAnswerShowIndex;
