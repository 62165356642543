// Importar librerías
import React, { useState, useEffect, useContext } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { Layout, Menu } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

// Importar rutas
import * as ROUTES from './../../constants/routes';

// Importar context de firebase y de user
import { FirebaseContext } from '../../context';

// Importar otros componentes
import { ReactComponent as AccountIcon } from './icons/account.svg';
import { ReactComponent as AssignmentIcon } from './icons/assignment.svg';
import { ReactComponent as BankIcon } from './icons/bank.svg';
import { ReactComponent as HomeIcon } from './icons/home.svg';
import { ReactComponent as SignOutIcon } from './icons/signOut.svg';
import { ReactComponent as Logo } from './icons/LOGO FLIT.svg';
import { ReactComponent as RequirementIcon } from './icons/requirements.svg';

// Importar constantes
import * as ROLES from './../../constants/roles';

// Importar hooks
import useWindowSize from '../../hooks/useWindowSize';

// Importar subcomponentes
const { Sider } = Layout;

const CustomSider = ({ authUser }) => {
  // Definir context
  const firebase = useContext(FirebaseContext);

  // Definir state
  const [showWarningIcon, setShowWarningIcon] = useState(false);

  // Definir nueva instancia de useLocation
  const location = useLocation();

  // Obtener ancho de la ventana
  const windowWidth = useWindowSize();

  // Definir effect para obtener el valor de las solicitudes
  useEffect(() => {
    const getApps = async () => {
      // Obtener información de las cotizaciones
      const values = await firebase.db
        .collection('applications')
        .where('winner.executiveId', '==', authUser.uid)
        .where('status', '==', 'accepted')
        .get();

      // Validar si existen solicitudes y setear el valor del booleano que muestra el ícono
      // si se dan las condiciones
      if (values.docs.length) {
        const appWarnings = values.docs.filter(
          (item) =>
            item.get('chat').length >= 2 &&
            item.get('chat')[item.get('chat').length - 1].senderId !== authUser.uid
        );
        setShowWarningIcon(appWarnings.length >= 1);
      }
    };

    if (location.pathname === '/solicitudes-adjudicadas') {
      getApps();
    }
  }, [location, firebase, authUser]);

  // Renderizar según sea el caso
  if (
    location.pathname === ROUTES.SIGN_IN ||
    location.pathname === ROUTES.SIGN_UP ||
    location.pathname === ROUTES.PASSWORD_FORGET
  ) {
    return (
      <>
        {windowWidth > 992 && (
          <Sider
            theme="light"
            width={'640px'}
            className="session-sider"
            breakpoint={'lg'}
          >
            <div className={'sider-session-image'}></div>
          </Sider>
        )}
      </>
    );
  }

  // Renderizar
  return (
    <Sider id="side-menu" breakpoint={'lg'} collapsed={false}>
      <div className="logo">
        <Logo />
      </div>
      <Menu
        mode="inline"
        className="side-content-items"
        theme="dark"
        defaultSelectedKeys={['1']}
        activeKey={[location.pathname]}
        selectedKeys={['/' + location.pathname.split('/')[1]]}
      >
        <Menu.Item key={ROUTES.HOME} className="side-item">
          <Link to={ROUTES.HOME}>
            <HomeIcon />
            <p>Inicio</p>
          </Link>
        </Menu.Item>
        {authUser && !!authUser.roles[ROLES.BANK_ADMIN] && (
          <Menu.Item key={ROUTES.EXECUTIVES_PAGE} className="side-item">
            <Link to={ROUTES.EXECUTIVES_PAGE}>
              <BankIcon />
              <p>Ejecutivos del Banco</p>
            </Link>
          </Menu.Item>
        )}
        {authUser && !!authUser.roles[ROLES.BANK_ADMIN] && (
          <Menu.Item key={ROUTES.ASSIGN_LIST_PAGE} className="side-item">
            <Link to={ROUTES.ASSIGN_LIST_PAGE}>
              <AssignmentIcon />
              <p>Asignar Solicitud</p>
            </Link>
          </Menu.Item>
        )}

        {authUser && !!authUser.roles[ROLES.BANK_EXECUTIVE] && (
          <Menu.Item key={ROUTES.APPLICATIONS_PAGE} className="side-item">
            <Link to={ROUTES.APPLICATIONS_PAGE}>
              <AssignmentIcon />
              <p>Solicitudes Asignadas</p>
            </Link>
          </Menu.Item>
        )}
        {authUser && !!authUser.roles[ROLES.BANK_EXECUTIVE] && (
          <Menu.Item key={ROUTES.TAKEN_APPLICATIONS_PAGE} className="side-item">
            <Link to={ROUTES.TAKEN_APPLICATIONS_PAGE}>
              <AccountIcon />
              <p>Solicitudes Adjudicadas</p>
              {showWarningIcon && (
                <ExclamationCircleOutlined className="info-icon-menu-style" />
              )}
            </Link>
          </Menu.Item>
        )}
        {authUser && !!authUser.roles[ROLES.BANK_EXECUTIVE] && (
          <Menu.Item key={ROUTES.REQUIREMENTS_PAGE} className="side-item">
            <Link to={ROUTES.REQUIREMENTS_PAGE}>
              <RequirementIcon />
              <p>Precargar Requerimientos</p>
            </Link>
          </Menu.Item>
        )}
        <Menu.Item key={ROUTES.SIGN_OUT} className="side-item sign-out-item">
          <Link to={ROUTES.SIGN_OUT}>
            <SignOutIcon />
            <p>Cerrar Sesión</p>
          </Link>
        </Menu.Item>
      </Menu>
    </Sider>
  );
};

export default CustomSider;
