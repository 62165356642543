// Importar librerías
import React, { useState } from 'react';
import { compose } from 'recompose';
import { Button } from 'antd';

// Importar otros componentes
import PasswordChangeModal from './../Session/passwordChangeModal';

// Importar utilidades y constantes
import { withAuthorization, withHardAuthorization, withAuthUser } from '../../utils';
import {
  authenticatedUserCondition,
  bankGeneralUserCondition,
} from '../../constants/conditions';

// Definir roles para la administración del Banco
const roleNames = {
  BANKADMIN: 'Administrador de Banco',
  BANKEXECUTIVE: 'Ejecutivo de Banco',
};

const HomePage = ({ authUser }) => {
  // Definir state
  const [changeOpen, setChangeOpen] = useState(false);

  // Almacenar requerimientos comunes en localStorage
  localStorage.setItem(
    'commonRequirements',
    JSON.stringify(authUser.commonRequirements)
  );

  // Renderizar componente
  return (
    <div className="homeContent">
      <div className="info">
        <div className="mainInfo">
          <h3>{`${authUser.firstName}  ${authUser.lastName}`}</h3>
          <p>{authUser.roles.BANKADMIN && roleNames[authUser.roles.BANKADMIN]}</p>
          <p>
            {authUser.roles.BANKEXECUTIVE && roleNames[authUser.roles.BANKEXECUTIVE]}
          </p>
        </div>
        <div className="secondaryInfo">
          <p>
            <span>Banco: </span>
            {authUser.bankName}
          </p>
          {authUser.bankOfficeName && (
            <p>
              <span>Sucursal: </span>
              {authUser.bankOfficeName}
            </p>
          )}
          <p>
            <span>Correo Electrónico: </span>
            {authUser.email}
          </p>
        </div>
        <div className={'change-password'}>
          <Button onClick={() => setChangeOpen(!changeOpen)}>
            Cambiar Mi Contraseña
          </Button>
          <PasswordChangeModal openModal={changeOpen} setOpenModal={setChangeOpen} />
        </div>
      </div>
    </div>
  );
};

export default compose(
  withAuthUser,
  withAuthorization(authenticatedUserCondition),
  withHardAuthorization(bankGeneralUserCondition)
)(HomePage);
