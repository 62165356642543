// *** Executive API ***
class executiveLogic {
  constructor(db, fieldValue, functions) {
    this.db = db;
    this.fieldValue = fieldValue;
    this.functions = functions;
  }

  executive = (uid) => this.db.doc(`users/${uid}`);

  executives = (bankId, bankOfficeId) =>
    this.db
      .collection('users')
      .where('bankId', '==', bankId)
      .where('bankOfficeId', '==', bankOfficeId)
      .where('roles.BANKEXECUTIVE', '==', 'BANKEXECUTIVE');

  executiveEdit = (oldExecutive, newValues) => {
    const oldValues = {
      firstName: oldExecutive.firstName,
      lastName: oldExecutive.lastName,
      email: oldExecutive.email,
    };
    if (JSON.stringify(oldValues) === JSON.stringify(newValues)) {
      return Promise.reject(new Error('No changes'));
    }
    return this.executive(oldExecutive.uid).update(newValues);
  };

  checkBankUser = (email) => {
    // Dado un email, revisa que ese usuario no exista de antes con un rol dado.

    const query = this.db.collection('users').where('email', '==', email);
    return query.get().then(function (querySnapshot) {
      if (querySnapshot.empty) {
        return Promise.resolve();
      } else {
        const roles = querySnapshot.docs[0].get('roles');
        if (roles.ADMIN || roles.BANKEXECUTIVE || roles.BANKADMIN || roles.NORMAL)
          return Promise.reject({ message: 'Ese usuario ya tiene un rol asignado' });
      }
    });
  };

  /**
   *
   * @param {*} content
   * Crea un nuevo ejecutivo en la Base de Datos.
   */
  newExecutive = async (content) => {
    try {
      // Verificar que el email no tenga un rol asignado
      const {
        uid,
        email,
        bankOfficeId,
        bankOfficeName,
        bankOfficeBoss,
        bankOfficeAppTypes,
      } = content;
      await this.checkBankUser(email);

      // Crear Usuario
      var createUser = this.functions.httpsCallable('createUser');
      await createUser(content);

      // Obtener documento del nuevo usuario
      const query = await this.db
        .collection('users')
        .where('email', '==', email)
        .get();
      if (query.empty) {
        return Promise.reject(
          new Error(
            'Hubo un error al intentar crear la información del ejecutivo en la base de datos'
          )
        );
      }

      // Definir arreglo de promesas
      let promises = [
        this.db.doc(`banks/${uid}`).update({
          bankExecutives: this.fieldValue.arrayUnion(email),
        }),
      ];

      if (bankOfficeId && bankOfficeName && bankOfficeBoss && bankOfficeAppTypes) {
        // Actualizar colección de bankOffices
        promises.push(
          this.db.doc(`bankOffices/${bankOfficeId}`).update({
            bankOfficeExecutives: this.fieldValue.arrayUnion(email),
          })
        );

        // Actualizar información de la colección users
        promises.push(
          this.db.doc(`users/${query.docs[0].id}`).update({
            bankOfficeId,
            bankOfficeName,
            bankOfficeBoss,
            bankOfficeAppTypes,
          })
        );
      }

      // Devolver resultado de las promesas
      return Promise.all(promises);
    } catch (error) {
      console.log(error);
      return Promise.reject(error);
    }
  };

  /**
   *
   * @param {*} content
   * Actualiza la información de un ejecutivo libre dentro de la bankOffices.
   */
  updateFreeExecutive = async (content) => {
    try {
      // Obtener valores de entrada
      const {
        executiveEmail,
        executiveId,
        bankOfficeId,
        bankOfficeName,
        bankOfficeBoss,
        bankOfficeAppTypes,
      } = content;

      // Definir arreglo de promesas
      const promises = [
        this.db.doc(`bankOffices/${bankOfficeId}`).update({
          bankOfficeExecutives: this.fieldValue.arrayUnion(executiveEmail),
        }),
        this.db.doc(`users/${executiveId}`).update({
          bankOfficeId,
          bankOfficeName,
          bankOfficeBoss,
          bankOfficeAppTypes,
        }),
      ];

      // Devolver resultado de las promesas
      return Promise.all(promises);
    } catch (error) {
      return Promise.reject(
        new Error(
          'Hubo un error al intentar actualizar la información del ejecutivo en la base de datos'
        )
      );
    }
  };

  /**
   *
   * @param {*} content
   * Elimina la información de un ejecutivo de la colección banks o bankOffices según
   * sea el caso.
   */
  executiveDelete = async (content) => {
    try {
      // Obtener valores de entrada
      const { bankId, bankOfficeId, email, executiveId } = content;

      // Definir conjutno de promesas según sea el caso
      let promises = [];
      if (bankOfficeId) {
        // Actualizar colección de bankOffices
        promises.push(
          this.db.doc(`bankOffices/${bankOfficeId}`).update({
            bankOfficeExecutives: this.fieldValue.arrayRemove(email),
          })
        );

        // Actualizar información de la colección users
        promises.push(
          this.db.doc(`users/${executiveId}`).update({
            bankOfficeId: '',
            bankOfficeName: '',
            bankOfficeBoss: '',
            bankOfficeAppTypes: '',
          })
        );
      } else {
        promises.push(
          this.db.doc(`banks/${bankId}`).update({
            bankExecutives: this.fieldValue.arrayRemove(email),
          })
        );
      }

      // Devolver resultado de las promesas
      return Promise.all(promises);
    } catch (error) {
      return Promise.reject(
        new Error(
          'Hubo un error al intentar eliminar la información del ejecutivo en la base de datos'
        )
      );
    }
  };
}

export default executiveLogic;
