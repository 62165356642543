// Importar librerías
import React, { useContext } from 'react';
import { Switch, Route } from 'react-router-dom';

// Importar utilidades y constantes
import { withAuthorization } from '../../utils';
import { bankGeneralUserCondition } from '../../constants/conditions';

// Importar context de user
import { AuthUserContext } from '../../context';

// Importar rutas
import * as ROUTES from '../../constants/routes';

// Importar otros componentes
import TakenApplicationList from './TakenApplicationList';
import TakenApplicationItem from './TakenApplicationItem';

const TakenApplicationsPage = () => {
  // Definir context de user
  const authUser = useContext(AuthUserContext);

  // Renderizar componente
  return (
    <div>
      <Switch>
        <Route
          exact
          path={ROUTES.TAKEN_APPLICATIONS_PAGE}
          render={(props) => <TakenApplicationList {...props} authUser={authUser} />}
        />
        <Route
          exact
          path={ROUTES.TAKEN_APPLICATION_PAGE}
          render={(props) => <TakenApplicationItem {...props} authUser={authUser} />}
        />
      </Switch>
    </div>
  );
};

export default withAuthorization(bankGeneralUserCondition)(TakenApplicationsPage);
