// Importar librerías
import React, { useState, useEffect } from 'react';
import { Tooltip, Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { ReactComponent as DownloadIcon } from './assets/downloadBlue.svg';
import moment from 'moment';

// Importar otros componentes
import NewRequirementModal from './NewRequirementModal';

// Definir formato de fecha
const DATE_FORMAT = 'DD/MM/YYYY - HH:mm';

const TakenApplicationRequirements = ({ application, firebase, rejected }) => {
  // Definir state
  const [openNewRequirementModal, setOpenNewRequirementModal] = useState(false);
  const [fullRequirements, setFullRequirements] = useState([]);
  const [sortedRequirements, setSortedRequirements] = useState({ old: [], new: [] });
  const [requirements, setRequirements] = useState(application.get('requirements'));

  // Definir effect para setear la información de los requerimientos
  useEffect(() => {
    setRequirements(application.get('requirements'));
  }, [application]);

  // Definir effect para setear la información de los archivos asociados a los requerimientos
  useEffect(() => {
    const loadFiles = async (requirements) => {
      console.log('running');
      const aux = [];
      const promises = [];
      requirements.forEach((req, i) => {
        const copyReq = Object.assign({}, req);
        const newPromise = copyReq.answer.files
          ? copyReq.answer.files.map(firebase.getDownloadURL)
          : [];

        promises.push(...newPromise);
        copyReq.urls = newPromise;
        copyReq.names = copyReq.answer.files
          ? copyReq.answer.files.map((d) => d.split(application.id + '/')[1])
          : [];

        // Archivo que mando el ejecutivo
        if (copyReq.file) {
          const exUpload = firebase.getDownloadURL(copyReq.file);
          promises.push(exUpload);
          copyReq.exFileUrl = exUpload;
          copyReq.exFileName = copyReq.file.split(application.id + '/')[1];
        }

        aux.push(copyReq);
      });
      await Promise.all(promises.map((p) => p.catch((e) => null)));

      setFullRequirements(aux);
    };
    loadFiles(requirements);
  }, [application.id, firebase, firebase.getDownloadURL, requirements]);

  // Definir effect para ordenar la lista de requerimientos
  useEffect(() => {
    let oldRequirements = [];
    let newRequirements = [];

    fullRequirements.forEach((req) => {
      if (req.answer.files && req.answer.files[0]) {
        oldRequirements.push(req);
      } else {
        newRequirements.push(req);
      }
    });

    oldRequirements.sort((o1, o2) => o2.createdAt.toDate() - o1.createdAt.toDate());
    newRequirements.sort((o1, o2) => o2.createdAt.toDate() - o1.createdAt.toDate());

    setSortedRequirements({ old: oldRequirements, new: newRequirements });
  }, [fullRequirements]);

  // Renderizar componente
  return (
    <div id={'requirements-container'}>
      <NewRequirementModal
        openNewRequirementModal={openNewRequirementModal}
        setOpenNewRequirementModal={setOpenNewRequirementModal}
        application={application}
        setRequirements={setRequirements}
      />
      <div className={'buttons'}>
        <Tooltip title={rejected ? 'Rechazaste esta solicitud' : ''}>
          <Button
            icon={<PlusOutlined />}
            type={'primary'}
            onClick={() => setOpenNewRequirementModal(true)}
            disabled={rejected}
          >
            Agregar Nuevo
          </Button>
        </Tooltip>
      </div>
      <div className="requirements">
        {sortedRequirements.new.length > 0 && (
          <div className="new">
            <p className="head">Más recientes ({sortedRequirements.new.length})</p>
            {sortedRequirements.new.map((d, i) => {
              return (
                <div key={d.message + i} className={'requirement new-req'}>
                  <div className={'title'}>
                    <h4>{moment(d.createdAt.toDate()).format(DATE_FORMAT)}</h4>
                    <h3>{d.title}</h3>
                  </div>
                  <div className={'info'}>
                    <p>{d.message}</p>
                    {d.file && (
                      <a
                        href={d.exFileUrl.i}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {d.exFileName} <DownloadIcon />
                      </a>
                    )}
                  </div>
                  <div className={'answer'}>
                    <p>INFORMACIÓN REQUERIDA</p>
                  </div>
                </div>
              );
            })}
          </div>
        )}
        {sortedRequirements.old.length > 0 && (
          <div className="old">
            <p className="head">Anteriores</p>
            {sortedRequirements.old.map((d, i) => {
              return (
                <div key={d.message + i} className={'requirement old-req'}>
                  <div className={'title'}>
                    <h4>{moment(d.createdAt.toDate()).format(DATE_FORMAT)}</h4>
                    <h3>{d.title}</h3>
                  </div>
                  <div className={'info'}>
                    <p>{d.message}</p>
                    {d.file && <p>{d.file}</p>}
                  </div>
                  <div className={'answer'}>
                    {d.answer.files.map((file, i) => {
                      const name = file.split(application.id + '/')[1];
                      return (
                        <a
                          key={d.urls[i].i + i}
                          href={d.urls[i].i}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {name} <DownloadIcon />
                        </a>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default TakenApplicationRequirements;
