// Importar librerías
import React, { useState, useContext } from 'react';
import { Modal, Button, Form, Input, Checkbox, message } from 'antd';

// Importar context de firebase y user
import { FirebaseContext, AuthUserContext } from './../../context';

// Importar hooks
import useWindowSize from '../../hooks/useWindowSize';

const NewExecutiveModal = ({
  openNewExecutiveModal,
  setOpenNewExecutiveModal,
  appOptions,
}) => {
  // Obtener información del context de firebase y user
  const firebase = useContext(FirebaseContext);
  const authUser = useContext(AuthUserContext);

  // Definir state
  const [loading, setLoading] = useState(false);

  // Definir nueva instancia de useForm
  const [form] = Form.useForm();

  // Obtener tamaño de la ventana
  const windowWidth = useWindowSize();

  /**
   *
   * @param {*} values
   * Almacena la información en la BD cuando se haya llenado toda la información del formulario.
   */
  const onFinish = async (values) => {
    // Habilitar carga de información
    setLoading(true);

    // Agregar valores adicionales
    values = {
      ...values,
      email: values.email.toLowerCase(),
      uid: authUser.bankId,
      role: 'BANKEXECUTIVE',
      bankName: authUser.bankName,
      bankOfficeId: authUser.bankOfficeId,
      bankOfficeName: authUser.bankOfficeName,
      bankOfficeBoss: authUser.email,
    };

    // Intentar almacenar en la BD
    try {
      await firebase.executiveLogic.newExecutive(values);

      // Mostrar mensaje de éxito
      message.success('Ejecutivo creado exitosamente');

      // Borrar valores del formulario
      form.resetFields();

      // Setear valores de los estados de entrada
      setOpenNewExecutiveModal(false);
    } catch (error) {
      message.error(error.message);
    }

    setLoading(false);
  };

  /**
   *
   * @param {*} errorInfo
   * Muestra un mensaje de error si el ejecutivo de banco no se ha creado correctamente.
   */
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  // Renderizar componente
  return (
    <Modal
      onCancel={() => {
        form.resetFields();
        setOpenNewExecutiveModal(false);
      }}
      confirmLoading={loading}
      footer={<div id="executive-modal-footer"></div>}
      width={windowWidth < 992 ? 350 : 520}
      visible={openNewExecutiveModal}
    >
      <div id="executive-new-modal-body">
        <h3>Agregar Nuevo Ejecutivo</h3>
        <Form
          name="executive_form"
          id="modal-new-executive-form"
          form={form}
          layout="vertical"
          hideRequiredMark={true}
          onFinish={(values) => onFinish(values)}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            label="Nombre"
            name="firstName"
            rules={[{ required: true, message: 'Agregue el nombre del ejecutivo' }]}
          >
            <Input disabled={loading} />
          </Form.Item>
          <Form.Item
            label="Apellido"
            name="lastName"
            rules={[
              {
                required: true,
                message: 'Agregue el apellido del ejecutivo',
              },
            ]}
          >
            <Input disabled={loading} />
          </Form.Item>
          <Form.Item
            label="Email del Ejecutivo"
            name="email"
            rules={[{ required: true, message: 'Agregue el email del ejecutivo' }]}
          >
            <Input disabled={loading} />
          </Form.Item>
          {authUser.bankOfficeName && (
            <Form.Item
              label="Tipo de Solicitudes"
              name="bankOfficeAppTypes"
              rules={[
                {
                  required: true,
                  message:
                    'Seleccione al menos un tipo de solicitud que maneje el ejecutivo',
                },
              ]}
            >
              <Checkbox.Group options={appOptions} />
            </Form.Item>
          )}
          <Form.Item className={'button-holder'}>
            <Button type="primary" htmlType="submit" loading={loading}>
              Crear
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

export default NewExecutiveModal;
